import { NzModalService } from 'ng-zorro-antd/modal';
import {
  Component,
  ViewChild,
  AfterViewInit,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@app/core/services';

enum NotificationColors {
  success = '#52c41a',
  error = '#ff4d4f',
  info = '#1890ff',
}

enum IconTypes {
  success = 'check-circle',
  error = 'close-circle',
  info = 'info-circle',
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements AfterViewInit {
  @ViewChild('template', { static: true }) modalTemplate: any;

  templateRef: TemplateRef<any>;
  data: {
    iconType: string;
    color: string;
    notificationType: string;
    leftPartOfText?: string;
    description: string;
    rightPartOfText?: string;
  } = {
      iconType: '',
      color: '',
      notificationType: '',
      leftPartOfText: '',
      description: '',
      rightPartOfText: '',
    };

  notificationColors = NotificationColors;
  iconTypes = IconTypes;


  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private modalService: NzModalService,
  ) { }

  ngAfterViewInit() {
    this.notificationService.setTemplate(this.modalTemplate);
  }

  goToKnowledgeBase(): void {
    // eslint-disable-next-line max-len
    window.open('https://smart-hub-io.atlassian.net/wiki/spaces/KBS/overview', '_blank');
  }

  goToSettings(): void {
    this.router.navigate(['settings']);
    this.modalService.closeAll();
  }

}
