import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  DoCheck,
  Output,
  EventEmitter,
} from '@angular/core';
import { DashboardFilterModel } from '@app/core/interfaces';
import { MainPageService } from '@core/services';

@Component({
  selector: 'app-select-tree-main',
  templateUrl: './select-tree-main.component.html',
  styleUrls: ['./select-tree-main.component.scss'],
})
export class SelectTreeMainComponent implements DoCheck {

  @Input() filters: DashboardFilterModel[];
  @Input() side: string;
  @Input() public set tableHeight(value: string | number) {
    this._tableHeight = value;
  }

  public get tableHeight(): string | number {
    return this._tableHeight;
  }

  public filterCriteries = {
    ssp: {},
    dsp: {},
  };
  @Output() changeSelect = new EventEmitter();
  isShowFilter = false;
  @ViewChild('filter') filter: ElementRef;

  filterHeight: any;

  private _tableHeight: string | number;

  constructor(
    private mainPageService: MainPageService,
  ) { }

  ngDoCheck(): void {
    if (this.filter !== undefined) {
      this.filterHeight = this.filter.nativeElement.offsetHeight;
      if (this.filterHeight > this.tableHeight) {
        this.filter.nativeElement.style.overflowY = 'scroll';
        this.filter.nativeElement.style.height = (this.filterHeight - 30) + 'px';
      }
    }
  }

  toggleState(e: MouseEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.isShowFilter = !this.isShowFilter;
  }

  toggleStateEnabled(e: MouseEvent, item: DashboardFilterModel): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    item.active = !item.active;
    this.isShowFilter = true;
  }

  changeFilterOption(value: string[], item: DashboardFilterModel, side: string): void {
    const neededFilter = this.filterCriteries[side][item.name];
    if (neededFilter === undefined) {
      this.filterCriteries[side][item.name] = [];
    }
    this.filterCriteries[side][item.name] = value;
    this.mainPageService.filters[side] = this.filterCriteries[side];
  }

  search(side: string): void {
    this.changeSelect.emit(this.filterCriteries[side]);
    this.isShowFilter = !this.isShowFilter;
  }

}
