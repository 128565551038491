import { TableConfig, SellersJsonTableData } from '../../interfaces';

export const sellerJsonTableConfig: TableConfig.IDataTableConfig = {
  columns: [
    {
      label: 'Type',
      rowProperty: 'type',
      sortable: false,
      width: '180px',
      columnFn: (partnerId: number) => true,
    },
    {
      label: 'Seller ID',
      rowProperty: 'seller_id',
      sortable: true,
      width: '180px',
      hasFunc: true,
      columnFn: (partnerId: number) => partnerId !== 360,
    },
    {
      label: 'MarkApp Seller ID',
      rowProperty: 'seller_id',
      sortable: true,
      width: '180px',
      hasFunc: true,
      columnFn: (partnerId: number) => partnerId === 360,
    },
    {
      label: 'OCM Seller ID',
      rowProperty: 'second_seller_id',
      sortable: true,
      width: '180px',
      hasFunc: true,
      columnFn: (partnerId: number) => partnerId === 360,
    },
    {
      label: 'Seller Name',
      rowProperty: 'name',
      sortable: true,
      width: '180px',
      columnFn: (partnerId: number) => true,
    },
    {
      label: 'Domain',
      rowProperty: 'domain',
      sortable: true,
      width: '180px',
      columnFn: (partnerId: number) => true,
    },
    {
      label: 'Seller Type',
      rowProperty: 'seller_type',
      sortable: true,
      width: '180px',
      columnFn: (partnerId: number) => true,
    },
    {
      label: 'Confidentiality',
      rowProperty: 'is_confidentiality',
      sortable: true,
      width: '180px',
      columnFn: (partnerId: number) => true,
    },
    {
      label: 'Hide',
      rowProperty: 'hide',
      sortable: false,
      width: '80px',
      columnFn: (partnerId: number) => true,
    },
    {
      label: 'Action',
      rowProperty: 'action',
      sortable: false,
      width: '180px',
      columnFn: (partnerId: number) => true,
    },
  ],
  rowActions: [
    {
      rowName: 'type',
      hasFunc: false,
      action: [],
      pipe: 'titlecase',
      width: '60%',
      rowFn: (item: SellersJsonTableData) => item.type.charAt(0).toUpperCase() + item.type.slice(1),
      hasControl: false,
      checkPartnerId: (partnerId: number) => true,
    },
    {
      rowName: 'seller_id',
      hasFunc: false,
      action: [],
      width: '60%',
      hasControl: false,
      checkPartnerId: (partnerId: number) => partnerId !== 360,
    },
    {
      rowName: 'seller_id',
      hasFunc: false,
      action: [],
      width: '60%',
      hasControl: false,
      checkPartnerId: (partnerId: number) => partnerId === 360,
    },
    {
      rowName: 'second_seller_id',
      hasFunc: false,
      action: [],
      width: '60%',
      hasControl: false,
      checkPartnerId: (partnerId: number) => partnerId === 360,
    },
    {
      rowName: 'name',
      hasFunc: false,
      action: [],
      width: '60%',
      hasControl: false,
      checkPartnerId: (partnerId: number) => true,
    },
    {
      rowName: 'domain',
      hasFunc: false,
      hasControl: false,
      width: '60%',
      action: [],
      checkPartnerId: (partnerId: number) => true,
    },
    {
      rowName: 'seller_type',
      hasFunc: false,
      hasControl: false,
      width: '60%',
      pipe: 'uppercase',
      rowFn: (item: SellersJsonTableData) => item.seller_type.toUpperCase(),
      action: [],
      checkPartnerId: (partnerId: number) => true,
    },
    {
      rowName: 'is_confidentiality',
      hasFunc: true,
      hasControl: false,
      width: '60%',
      checkPartnerId: (partnerId: number) => true,
      rowFn(data: SellersJsonTableData) {
        if (data.is_confidentiality && !data.domain) {
          return 'Is confidential';
        } else if (data.is_confidentiality && data.domain) {
          return 'Is confidential';
        } else {
          return 'Not confidential';
        }
      },
      action: []
    },
    {
      rowName: 'is_hide',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      checkPartnerId: (partnerId: number) => true,
      action: [
        { actionType: '', label: '', element: 'checkbox', iconName: '' },
      ]
    },
    {
      rowName: 'is_slack_alerts',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      checkPartnerId: (partnerId: number) => true,
      action: [
        { actionType: 'startEdit', label: 'Edit', element: 'button', iconName: '' },
      ]
    },
  ],
  type: 'sellerJson',
};

export const editSellerJsonTableConfig: TableConfig.IDataTableConfig = {
  columns: [
    {
      label: 'Type',
      rowProperty: 'type',
      sortable: true,
      width: '180px'
    },
    {
      label: 'Seller ID',
      rowProperty: 'seller_id',
      sortable: true,
      width: '180px',
      hasFunc: true,
      columnFn: (partnerId: number) => partnerId !== 360,
    },
    {
      label: 'MarkApp Seller ID',
      rowProperty: 'seller_id',
      sortable: true,
      width: '180px',
      hasFunc: true,
      columnFn: (partnerId: number) => partnerId === 360,
    },
    {
      label: 'OCM Seller ID',
      rowProperty: 'second_seller_id',
      sortable: true,
      width: '180px',
      hasFunc: true,
      columnFn: (partnerId: number) => partnerId === 360,
    },
    {
      label: 'Seller Name',
      rowProperty: 'name',
      sortable: true,
      width: '180px'
    },
    {
      label: 'Domain',
      rowProperty: 'domain',
      sortable: true,
      width: '180px'
    },
    {
      label: 'Seller Type',
      rowProperty: 'seller_type',
      sortable: true,
      width: '180px'
    },
    {
      label: 'Confidentiality',
      rowProperty: 'is_confidentiality',
      sortable: true,
      width: '180px'
    },
    {
      label: 'Hide',
      rowProperty: 'hide',
      sortable: false,
      width: '80px',
    },
    {
      label: 'Action',
      rowProperty: 'action',
      sortable: false,
      width: '180px'
    },
  ],
  rowActions: [
    {
      rowName: 'type',
      hasFunc: true,
      action: [],
      hasControl: false,
      width: '60%',
      checkPartnerId: (partnerId: number) => true,
      rowFn: (item: SellersJsonTableData) => item.type.charAt(0).toUpperCase() + item.type.slice(1),
    },
    {
      rowName: 'seller_id',
      hasFunc: false,
      width: '60%',
      checkPartnerId: (partnerId: number) => partnerId !== 360,
      action: [
        { actionType: '', label: '', element: 'input', iconName: '' },
      ],
      hasControl: true,
    },
    {
      rowName: 'seller_id',
      hasFunc: false,
      width: '60%',
      checkPartnerId: (partnerId: number) => partnerId === 360,
      action: [
        { actionType: '', label: '', element: 'input', iconName: '' },
      ],
      hasControl: true,
    },
    {
      rowName: 'second_seller_id',
      hasFunc: true,
      width: '60%',
      checkPartnerId: (partnerId: number) => partnerId === 360,
      action: [
        { actionType: '', label: '', element: 'input', iconName: '' },
      ],
      hasControl: true,
    },
    {
      rowName: 'name',
      hasFunc: false,
      width: '60%',
      checkPartnerId: (partnerId: number) => true,
      action: [
        { actionType: '', label: '', element: 'input', iconName: '' },
      ],
      hasControl: true,
    },
    {
      rowName: 'domain',
      hasFunc: false,
      width: '60%',
      hasControl: true,
      checkPartnerId: (partnerId: number) => true,
      action: [
        { actionType: '', label: '', element: 'input', iconName: '' },
      ],
    },
    {
      rowName: 'seller_type',
      hasFunc: false,
      hasControl: true,
      checkPartnerId: (partnerId: number) => true,
      width: '60%',
      rowFn: (data: SellersJsonTableData) => false,
      action: [
        {
          actionType: '', label: '', element: 'select', iconName: '', placeholder: 'Select Seller Type', selectItems: [
            { id: 'intermediary', name: 'Intermediary' },
            { id: 'publisher', name: 'Publisher' },
            { id: 'both', name: 'Both' }
          ],
        },
      ],
    },
    {
      rowName: 'is_confidentiality',
      hasFunc: true,
      hasControl: true,
      checkPartnerId: (partnerId: number) => true,
      width: '60%',
      rowFn: (data: SellersJsonTableData) => !data.domain,
      action: [
        {
          actionType: '', label: '', element: 'select', iconName: '', placeholder: 'Select Confidentiality', selectItems: [
            { id: true, name: 'Is confidential' },
            { id: false, name: 'Not confidential' }
          ],
        },
      ]
    },
    {
      rowName: 'is_hide',
      hasFunc: false,
      hasControl: true,
      checkPartnerId: (partnerId: number) => true,
      width: '60%',
      action: [
        { actionType: '', label: '', element: 'checkbox', iconName: '' },
      ]
    },
    {
      rowName: 'is_slack_alerts',
      hasFunc: false,
      hasControl: true,
      checkPartnerId: (partnerId: number) => true,
      width: '60%',
      action: [
        { actionType: 'saveEdit', label: 'Apply', element: 'button', iconName: '' },
        { actionType: 'cancelEdit', label: 'Cancel', element: 'popconfirm', iconName: '' },
      ]
    },
  ],
  type: 'sellerJson',
};