import { CookieSync } from '../cookie-sync';

export class DSP {
  id: number;
  iabOttCtv: number;
  partnerId: number;
  name: string;
  endpoint: string;
  banner: number;
  native: number;
  nativeSpec = [1, 2, 3];
  video: number;
  deletedAt: string;
  pop: number;
  push: number;
  audio: number;
  marga: number;
  nurl: number;
  margin: number;
  margin_type = 'fixed';
  min_margin: number;
  max_margin: number;
  limitQPS: number;
  realQPS: number;
  bidQPS: number;
  region: string;
  email: string;
  comments: string;
  companyId: number;
  mobileWeb: number;
  inApp: number;
  desktop: number;
  intstlOnly: number;
  active = 0;
  createDate: number;
  minTmax: number;
  secureOnly: number;
  useAdapter = 1;
  userIdRequired: number;
  dailySpend: number;
  spendLimit: 50;
  maxBidfloor: number;
  onlyKnownApps: number;
  matchedUsersOnly: number;
  filterPorn: number;
  rewarded: number;
  mismatchedIpFilter = 0;
  mismatchedBundlesFilters = 0;
  videoStartTracker: number;
  lowSecondPrice: number;
  adaptLearningPerc: 7;
  usePMBlack: number;
  usePMWhite: number;
  usePMKey: number;
  has_pm_postbid: number;
  useTCF: number;
  is_only_with_consent = 0;
  useFBlack: number;
  useFWhite: number;
  allowedSitesCount: number;
  allowedConnectionTypesList: string[];
  allowedCountriesList: string[];
  allowed_bundles_count: number;
  blocked_bundles_count: number;
  allowed_publishers_count: number;
  blocked_publishers_count: number;
  osList: string[] | any;
  allowedSizesList: string[];
  allowedSspList: any[];
  blockedSitesCount: number;
  blockedSspList: any[];
  blockedIPsList: any[];
  blockedIPsCount: number;
  apiLink: string;
  reportingApiLink: string;
  isBlockedCTV: number;
  useTotalSpendLimit: number;
  totalSpendLimit: number;
  usePixalatePBFilter: number;
  usePixalatePBIFAFilter: number;
  ctv: number;
  cookieSync: CookieSync;
  auctionType: number;
  connectionType: number;
  pmPrebidFraudIVT = 0;
  pmPrebidSuspiciousIVT = 0;
  defaultPriceVast: number;
  useConnector: number;
  connectorType = 0;
  deviceIdRequired: number;
  connectorImprove: ConnectorImprove;
  double_click: boolean;
  click_ttl = 24;
  allowCountriesTrigger = 1;
  allowSspTrigger = 1;
  cpc: any;
  pixalatePBpercent: number;
  pixalatePBIFApercent: number;
  sizeTrigger = 1;
  sizesList: any = [];
  osTrigger = 1;
  connectionTypeTrigger = 1;
  connectionTypesList: any = [];
  sspTrigger = 1;
  sspList: any = [];
  countriesTrigger = 1;
  countriesList: string[];
  playerSizes: PlayerSize;
  schain_filter_option = 1;
  schain_complete_option = 'origin';
  playerTrigger = 0;
  adServerId: number;
  sChain = 0;
  is_schain_complete = 0;
  useBotmanPrebid = 0;
  useBotmanPostbid = 0;
  allow_fraud = 0;
  useCustomIfa: number;
  enable_prebid = 0;
  pub_tag: PubTag;
  ipv4_required = false;
  ccpa_check = 1;
  adapter_id: string | number;
  adapter_config: any;
  vastConnectionMode?: number = null;
  rtbConnectionMode = 0;
  pixalate_pb_settings: EndpointPixalatePredidSettings;
  is_pixalate_postbid? = 0;
  enable_postbid = false;
  js: DspJs;
  selectedSize = 0;
  has_badv_limit = 0;
  has_iab_categories_limit = 0;
  is_omsdk = 0;
  filter_list_ids = [];
  rtb_type = '2.5';
  min_rcpm = 0;
  schain_value = 'markup';
  constructor() {
    this.js = new DspJs();
  }
}

export class EndpointPixalatePredidSettings {
  ip = 0;
  ip_percent = 0;
  ifa = 0;
  ifa_percent = 0;
  ua = 0;
  ua_percent = 0;
  ott = 0;
  ott_percent = 0;
  def_app = 0;
  def_app_percent = 0;
  domain = 0;
  domain_percent = 0;
  hr_app = 0;
  hr_app_percent = 0;
  dc_subnet = 0;
  dc_subnet_percent = 0;
}

export interface DSPEndpoint {
  active: number,
  name: string,
  connectionType: number,
  rtb_type: string[],
  sChain: number,
  is_schain_complete: number,
  useAdapter: number,
  vastConnectionMode: string,
  video: number,
  audio: number,
  banner: number,
  native: number,
  nativeSpec: number[],
  pop: number,
  push: number,
  rewarded: number,
  mobileWeb: number,
  inApp: number,
  desktop: number,
  ctv: number,
  companyId: number,
  region: string,
  margin_type: string,
  margin: number,
  min_margin: number,
  max_margin: number,
  defaultPriceVast: number,
  spendLimit: number,
  limitQPS: number,
  auctionType: number,
  minTmax: number,
  maxBidfloor: number,
  selectedSize: number,
  selectedPreBidServer: number,
  selectedAdapters: number,
  endpoint: string,
  nurl: number,
  apiLink: string,
  id: number,
  countriesTrigger: number,
  countriesList: number[],
  sspTrigger: number,
  sspList: number[],
  sizeTrigger: number,
  sizesList: string[],
  playerSizes: {
    trigger: number,
    size: string[],
    value: any[]
  },
  connectionTypeTrigger: number,
  connectionTypesList: number[],
  osTrigger: number,
  osList: string[],
  pmPrebidSuspiciousIVT: number,
  pmPrebidFraudIVT: number,
  mismatchedIpFilter: number,
  mismatchedBundlesFilters: number,
  iabOttCtv: number,
  isBlockedCTV: number,
  useTCF: number,
  is_only_with_consent: number,
  ccpa_check: number,
  deviceIdRequired: number,
  useCustomIfa: number,
  is_omsdk: number,
  ipv4_required: number,
  has_badv_limit: number,
  has_iab_categories_limit: number,
  is_pixalate_postbid: number,
  pixalate_pb_settings: {
    ip: number,
    ip_percent: number,
    ifa: number,
    ifa_percent: number,
    ua: number,
    ott: number,
    ott_percent: number,
    def_app: number,
    domain: number,
    hr_app: number,
    hr_app_percent: number,
    dc_subnet: number,
  },
  secureOnly: number,
  filterPorn: number,
}

export class PubTag {
  dsp_id: number;
  tag_id: string;
  pub_id: string;
}

export interface PlayerSize {
  trigger: number;
  size: any;
}

export interface DSPResponse {
  status: string;
  data: DSP[];
}

export interface DSPUpdateResponse {
  status: string;
  data: DSP;
}

export interface DSPResponseSingle {
  status: string;
  data: DSP;
}

export interface ConnectorImprove {
  id: number;
  dspId: number;
  exchangePartnerId: number;
  exchangeTagId: number;
  exchangePubId: number;
  siteId: number;
  appId: number;
}

export class DspJs {
  w: number;
  h: number;
  // dailyCap: number;
  cpm: number;
  macrosList: string[] = [];
}

export const macrosListJsDSP: { id: string, name: string, desc: string, param?: string }[] = [
  { id: '[IP]', name: '[IP]', desc: 'IPv4 address closest to device.' },
  { id: '[UA]', name: '[UA]', desc: 'Browser user agent string.' },
  { id: '[DOMAIN]', name: '[DOMAIN]', desc: 'Domain of the site (e.g., "mysite.foo.com"). Mandatory for Web traffic.' },
  {
    id: '[APP_BUNDLE]',
    name: '[APP_BUNDLE]',
    desc: `A platform-specific application identifier intended to be unique to the app and independent of the exchange.
    On Android, this should be a bundle or package name (e.g.,com.foo.mygame).
    On iOS, it is typically a numeric ID. Mandatory for InApp traffic.`,
  },
  { id: '[STORE_URL]', name: '[STORE_URL]', desc: 'App store URL for an installed app' },
  { id: '[PAGE_URL]', name: '[PAGE_URL]', desc: 'URL of the page where the impression will be shown. Mandatory for Web traffic' },
  { id: '[APP_NAME]', name: '[APP_NAME]', desc: 'App name' },
  { id: '[WIDTH]', name: '[WIDTH]', desc: 'ADM width' },
  { id: '[HEIGHT]', name: '[HEIGHT]', desc: 'ADM height' },
  { id: '[DEVICE_W]', name: '[DEVICE_W]', desc: 'Device width (optional)' },
  { id: '[DEVICE_H]', name: '[DEVICE_H]', desc: 'Device height (optional)' },
  { id: '[LOCATION_LAT]', name: '[LOCATION_LAT]', desc: 'Latitude from -90.0 to +90.0, where negative is south. If empty default - ip location' },
  { id: '[LOCATION_LON]', name: '[LOCATION_LON]', desc: 'Longitude from -180.0 to +180.0, where negative is west. If empty default - ip location' },
  { id: '[BIDFLOOR]', name: '[BIDFLOOR]', desc: 'Bidfloor (optional) if empty default - SSP config bidfloor' },
  {
    id: '[COPPA]',
    name: '[COPPA]',
    desc: 'A flag indicating traffic that is subject to the Children\'s Online Privacy Protection Act of the United States',
  },
  { id: '[GDPR_CONSENT]', name: '[GDPR_CONSENT]', desc: 'GDPR regulation user consent (optional) Highly recommended for GDPR region' },
  {
    id: '[CCPA_CONSENT]',
    name: '[CCPA_CONSENT]',
    desc: 'CCPA regulation user consent (optional) Highly recommended for California region',
  },
  { id: '[CATEGORY]', name: '[CATEGORY]', desc: 'IAB site content categories.' },
  {
    id: '[DNT]',
    name: '[DNT]',
    desc: 'Standard "Do Not Track" flag as set in the header by the browser, where 0 = tracking is unrestricted, 1 = do not track'
  },
  { id: '[CB]', name: '[CB]', desc: 'Random number to prevent caching' },
  {
    id: '[USER_ID]',
    name: '[USER_ID]',
    desc: 'Standard "The user id (cookie id) in the supplier\'s system',
  },
  { id: '[SCHAIN]', name: '[SCHAIN]', desc: 'Supply Chain object information' },
  { id: '[IFA]', name: '[IFA]', desc: 'ID sanctioned for advertiser use in the clear (i.e., not hashed). Mandatory for InApp traffic.' },
  {
    id: '[GDPR]',
    name: '[GDPR]',
    // eslint-disable-next-line max-len
    desc: 'The parameter states the fact of GDPR compliance of the source. If the parameter gdpr is not present or the value is not valid the server will check the GDPR compliance nevertheless. The server will do a lookup of the users IP address to see if user is from EU or not.'
  },
  { id: '[SCHAIN]', name: '[SCHAIN]', desc: 'Supply Chain' }
];

export const urlAttrsDSP = {
  '[DOMAIN]': 'domain',
  '[LOCATION_LON]': 'lon',
  '[LOCATION_LAT]': 'lat',
  '[REF_URL]': 'ref',
  '[APP_BUNDLE]': 'bundle',
  '[PAGE_URL]': 'page',
  '[WIDTH]': 'w',
  '[HEIGHT]': 'h',
  '[IFA]': 'ifa',
  '[STORE_URL]': 'store_url',
  '[APP_NAME]': 'name',
  '[DEVICE_TYPE]': 'devicetype',
  '[BIDFLOOR]': 'bidfloor',
  '[CONSENT]': 'consent',
  '[GDPR]': 'gdpr',
  '[API]': 'api',
  '[CARRIER]': 'carrier',
  '[MAX_DURATION]': 'maxduration',
  '[MIN_DURATION]': 'minduration',
  '[CB]': 'cb',
  '[UA]': 'ua',
  '[IP]': 'ip',
  '[NAME]': 'name',
  '[CCPA]': 'ccpa',
  '[GDPR_CONSENT]': 'gdpr_consent',
  '[USER_ID]': 'user_id',
  '[DEVICE_W]': 'device_w',
  '[DEVICE_H]': 'device_h',
};

export const connectionTypesNamesDSP = {
  0: 'RTB Endpoint URL',
  1: 'VAST Endpoint URL',
  3: 'JS Endpoint URL',
  4: 'PreBid Endpoint',
};

export interface RtbAdaptersItem {
  id: number | string;
  name: string;
  fields: string[];
  config;
}

