import { Component, OnInit, Input, AfterContentInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';

import { AppOptionsService } from '../../../core/services';
import { DropDownItem } from '@core/interfaces';

@Component({
  selector: 'app-column-option-drop',
  templateUrl: './column-option-drop.component.html',
  styleUrls: ['./column-option-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnOptionDropComponent implements OnInit, AfterContentInit {

  @Input() items: { attr: string, label: string, tooltip: string, active: boolean, disabled?: boolean }[];
  @Input() iconClass: string;
  @Input() tableHeight: number;
  @Input() multi: string;
  @Input() side: string;
  opened = false;
  @Output() optionChanged = new EventEmitter<{ attr: string, label: string, active: boolean }[]>();
  @Output() isOpened = new EventEmitter<boolean>();
  private currentDraggableEvent: DragEvent;
  draggableListLeft = [];
  metricFields = [
    'qps', 'incoming_qps', 'bidqps', 'dailySpend', 'defaultTmax', 'defaultBidfloor', 'spendLimit', 'winrate', 'limitQPS',
    'realQPS', 'bidQPS', 'render_rate', 'total_bid_rate', 'valid_bid_rate', 'fill_rate', 'win_rate'
  ];


  contentHeight = 'auto';
  contentMaxHeight = '700px';

  constructor(
    private appOptionsService: AppOptionsService,
  ) { }

  ngOnInit(): void {
    this.items.forEach((i: DropDownItem) => {
      if (i.active) {
        this.draggableListLeft.push({
          content: i.label,
          effectAllowed: 'move',
          disable: false,
          handle: true
        });
      }
    });
  }

  ngAfterContentInit(): void {
    document.body.addEventListener('click', () => {
      if (this.opened) {
        this.opened = false;
      }
    });

    if (this.tableHeight !== undefined) {
      this.contentHeight = this.tableHeight - 273 + 'px';
      window.addEventListener('resize', () => {
        this.contentHeight = this.tableHeight - 273 + 'px';
      });
    }
  }

  clickOutSide(_: string): void { }


  onDropdownBodyClicked(e: MouseEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  toggleOpened(e: MouseEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.opened = !this.opened;

  }

  toggleSelection(e: MouseEvent, attr: string): boolean {
    e.preventDefault();
    e.stopImmediatePropagation();
    //do stuff
    if (this.items.find(i => i.attr === attr).disabled) {
      return;
    }
    if (!this.multi) {
      this.onItemSelected(attr);
      this.opened = false;
      this.optionChanged.emit(this.items);
      return false;
    }

    this.onMultiItemSelected(attr);
    this.optionChanged.emit(this.items);
    this.isOpened.emit(this.opened);
  }

  private onItemSelected(attr: string): void {
    this.items.forEach((i: DropDownItem) => {
      if (i.attr !== attr) {
        i.active = false;
      } else {
        i.active = true;
      }
    });
  }

  private onMultiItemSelected(attr: string): void {
    this.items.forEach((i: DropDownItem) => {
      if (i.attr == attr) {
        i.active = !i.active;
      }
    });
  }


  onDragStart(event: DragEvent): void {
    this.currentDraggableEvent = event;
  }

  onDragged(item: {
    content: string;
    disable: boolean;
    effectAllowed: string;
    handle: boolean;
  }, list: {
    content: string;
    disable: boolean;
    effectAllowed: string;
    handle: boolean
  }[], effect: DropEffect): void {
    if (effect === 'move') {

      const index = list.indexOf(item);
      list.splice(index, 1);

      let changedField = {};
      let oldIndex = 0;
      this.items.map((field, i) => {
        if (field.label == item.content) {
          changedField = field;
          oldIndex = i;
        }
      });

      let newIndex = 0;
      this.draggableListLeft.map((field, i) => {
        if (field.content == item.content) {
          newIndex = i;
        }
      });

      const newList = [];
      this.items.map((field, i) => {
        if (newIndex < oldIndex) {
          if (i < newIndex) {
            newList.push(field);
          }

          if (i == newIndex) {
            newList[newIndex] = changedField;
            newList.push(field);
          }

          if (i > newIndex && i != oldIndex) {
            newList.push(field);
          }
        } else {
          if (i < newIndex && i != oldIndex) {
            newList.push(field);
          }

          if (i == newIndex) {
            newList.push(field);
            newList[newIndex] = changedField;
          }

          if (i > newIndex) {
            newList.push(field);
          }
        }

      });

      this.appOptionsService.setOption(newList, this.side);
    }
  }

  onDragEnd(event: DragEvent): void {
    this.currentDraggableEvent = event;
  }

  onDrop(event: DndDropEvent, list?: { content: string; disable: boolean; effectAllowed: string; handle: boolean }[]): void {
    if (list
      && (event.dropEffect === 'copy'
        || event.dropEffect === 'move')) {

      let index = event.index;

      if (typeof index === 'undefined') {

        index = list.length;
      }

      list.splice(index, 0, event.data);
    }
  }

  changeOptions(event: boolean, option: { active: boolean; attr: string; label: string; tooltip: string }): void {
    this.draggableListLeft = [];
    this.items.forEach((i: DropDownItem) => {
      if (i.active) {
        this.draggableListLeft.push({
          content: i.label,
          effectAllowed: 'move',
          disable: false,
          handle: true
        });
      }
    });
    this.appOptionsService.setOption(this.items, this.side);
  }

}
