import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Timezones } from '@core/constants';

@Component({
  selector: 'app-timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss']
})
export class TimezoneComponent implements OnInit, OnChanges {

  @Input() defaultTz: string;
  @Input() control: FormControl = new FormControl();
  @Output() changeTimeZone = new EventEmitter<string>();
  timezoneList: { id: string; name: string }[] = [];
  tz = 'UTC';
  constructor() {
  }

  ngOnInit(): void {
    this.buildTZ();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultTz && changes.defaultTz.currentValue) {
      this.tz = changes.defaultTz.currentValue;
    }
  }

  onTZUpdated(e: string): void {
    this.changeTimeZone.emit(this.tz);
  }

  private buildTZ(): void {
    for (const id in Timezones) {
      this.timezoneList.push({ id: id, name: Timezones[id] });
    }
  }

}
