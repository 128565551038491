import { BehaviorSubject } from 'rxjs';
import { NzNotificationDataOptions, NzNotificationService } from 'ng-zorro-antd/notification';
import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  // Reference to store the template when its initialized.
  templateRef: TemplateRef<any>;

  constructor(
    private notification: NzNotificationService,
  ) { }

  // Function to show notification
  showTemplate(options: NzNotificationDataOptions): void {
    this.notification.template(this.templateRef, { ...options });
  }

  // Function to set the template reference
  setTemplate(template: any) {
    this.templateRef = template;
  }
}
