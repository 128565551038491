import { Injectable } from '@angular/core';

import { PartnerSettings } from '../../interfaces';
import { AuthService } from './../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class BaseSourceService {
  partnerSettings: PartnerSettings;
  botmanSettings: any;
  constructor(
    private authService: AuthService,
  ) {
    this.partnerSettings = this.authService.partner as any as PartnerSettings;
    this.botmanSettings = this.partnerSettings.botman_settings;
  }

  get hasScanner(): boolean {
    return Boolean(this.authService.partner.useTMT ||
      this.partnerSettings.useBotman ||
      this.partnerSettings.usePixalate ||
      this.partnerSettings.usePMPreBid ||
      this.partnerSettings.usePixalatePrebid ||
      this.partnerSettings.useForensiq ||
      this.partnerSettings.usePM ||
      this.partnerSettings.useGeoedge ||
      (this.botmanSettings &&
        Object.keys(this.botmanSettings).length &&
        (this.botmanSettings.enable_postbid || this.botmanSettings.enable_prebid))
    );
  }
}