import { Component, Inject, Input } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

interface DeleteEndpointConfirm {
  entity: string;
  name: string;
  type?: string;
}

@Component({
  selector: 'app-delete-endpoint-confirm',
  templateUrl: './delete-endpoint-confirm.component.html',
  styleUrls: ['./delete-endpoint-confirm.component.scss']
})
export class DeleteEndpointConfirmComponent {

  constructor(
    @Inject(NZ_MODAL_DATA) public data: DeleteEndpointConfirm,
    private dialogRef: NzModalRef<DeleteEndpointConfirmComponent>,
  ) {}

  confirmClicked(value: boolean) {
    this.dialogRef.close(value);
  }

  closeDialog(value: boolean): void {
    this.dialogRef.close(value);
  }
}
