import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { AppOptionsService } from '../app-options';
import { DataManagerService } from '../data-manager';
import { MainSspCompany, MainDspCompany, MainFilters, CompanyIds, SspDspCompanies } from '../../interfaces';
import { AuthService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class MainPageService {
  sspCompanies = [];
  dspCompanies = [];
  sspSearch = '';
  dspSearch = '';
  sspSortOrder = 'desc';
  sspSortBy = 'name';
  dspSortOrder = 'desc';
  dspSortBy = 'name';
  sspEndpoints = [];
  dspEndpoints = [];
  mainSspCompanies$ = new BehaviorSubject<MainSspCompany[]>(this.sspCompanies);
  mainDspCompanies$ = new BehaviorSubject<MainDspCompany[]>(this.dspCompanies);
  mainDspEndpoints$ = new BehaviorSubject(this.dspEndpoints);
  mainSspEndpoints$ = new BehaviorSubject(this.sspEndpoints);
  isBusy = false;
  sspBusy = false;
  dspBusy = false;

  endpointsBySideByCompanies = { ssp: {}, dsp: {} };

  perPage = 25;
  sspTotal = 0;
  dspTotal = 0;
  sspCurrentPage = 1;
  dspCurrentPage = 1;
  filters: { dsp: object, ssp: object } = {
    dsp: {
      ad_format: [],
      region: [],
      integration_type: [],
      traffic_type: [],
    },
    ssp: {
      ad_format: [],
      region: [],
      integration_type: [],
      traffic_type: [],
    },
  };

  constructor(
    private appOptionsService: AppOptionsService,
    private dataManagerService: DataManagerService,
    private authService: AuthService,
    private loadingService: NgxSpinnerService,
  ) {}

  sideView(val: string) {
    return val == 'ssp' ? 'suplly' : 'demand';
  }

  getCurrentPageBySide(side: string) {
    return this[side + 'CurrentPage' as keyof object] + 1;
  }

  getCurrentTabView(side: string) {
    const endpointsOrComanies = this.authService.mainSspDspTableOpened;
    if (!endpointsOrComanies[this.sideView(side)]) {
      return 'companies';
    } else {
      return 'endpoints';
    }
  }

  filterProperties(side: string) {
    const activeEndpoint = this.appOptionsService.getOptionsMainActivity();
    return {
      filters: this.filters[side],
      ad_format: side === 'ssp' ? activeEndpoint.sspSelectedFormats : activeEndpoint.dspSelectedFormats,
      search: this[side + 'Search'],
      activity: activeEndpoint[side + 'Selected'],
      connectionType: activeEndpoint[side + 'SelectedConnection'],
      sortBy: this[side + 'SortBy'],
      sortOrder: this[side + 'SortOrder'],
    };
  }

  refreshCompanies(side: string, currentPage = 1) {
    const activeEndpoint = this.appOptionsService.getOptionsMainActivity();
    const endpointsOrComanies = this.authService.mainSspDspTableOpened;

    const filter = {
      filters: this.filters[side as keyof MainFilters],
      ad_format: side === 'ssp' ? activeEndpoint.sspSelectedFormats : activeEndpoint.dspSelectedFormats,
      search: this[side + 'Search' as keyof object],
      activity: activeEndpoint[side + 'Selected' as keyof object],
      connectionType: activeEndpoint[side + 'SelectedConnection' as keyof object],
      sortBy: this[side + 'SortBy' as keyof object],
      sortOrder: this[side + 'SortOrder' as keyof object],
      currentPage: currentPage,
    };

    this.isBusy = true;

    this[side + 'Busy' as keyof any] = true;
    this.loadingService.show();
  }

  resetCompanies(side: string) {
    this[side + 'Companies' as keyof any] = [];
    this[side + 'Endpoints' as keyof any] = [];
    this[side + 'CurrentPage' as keyof any] = 1;
    this.endpointsBySideByCompanies[side as keyof any] = {};
    (this[side + 'SelectedFormats' as keyof any]) =
      this.appOptionsService.getOptionsMainActivity()[side + 'SelectedFormats' as keyof any];
    this[side + 'TotalPage' as keyof any] = 0;
    this.refreshCompanies(side, 1);
  }

  getEndpointsForCompany(companyId, side) {
    let endpoints = [];
    for (const index in this.endpointsBySideByCompanies[side]) {
      if (index == companyId) {
        endpoints = this.endpointsBySideByCompanies[side][index];
      }
    }

    return endpoints;
  }

  pageChange(pageIndex: number, side: string) {
    this[side + 'Companies' as keyof any] = [];
    this[side + 'Endpoints' as keyof any] = [];
    this[side + 'CurrentPage' as keyof any] = pageIndex;
    this.refreshCompanies(side, pageIndex + 1);
  }
}
