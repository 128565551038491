import { Pipe, PipeTransform } from '@angular/core';
import { FilterListInterface } from '@app/core/interfaces';

@Pipe({
  name: 'searchSelect',
})
export class SearchSelectPipe implements PipeTransform {
  transform(companies: FilterListInterface.CompaniesDataGeneral[], name: string): FilterListInterface.CompaniesDataGeneral[] {

    if (!name || typeof name !== 'string' || name.length < 2) {
      return companies;
    }

    name = name.toLowerCase();

    // let endpointFilterParam = (type == 'ssp' ? 'name' : 'endpoint');
    const endpointFilterParam = 'name';
    const filteredCompanies = companies.filter(company => {
      if (company.name.toLowerCase().includes(name)) {
        return true;
      }
    });

    if (filteredCompanies.length) {
      return filteredCompanies;
    }

    const filteredByEndpoint = companies.filter(company => {
      const hasEndpoint = company.endpoints.find((endpoint) => {
        return (endpoint[endpointFilterParam].toLowerCase().includes(name)) || (endpoint.id.toString().includes(name));
      });

      if (hasEndpoint) {
        return true;
      }

    });
    if (!filteredByEndpoint.length) {
      if (filteredCompanies.length) {
        return filteredCompanies;
      }
      return [];
    }

    const final = filteredByEndpoint.map((company) => {
      const filteredEndpoints = company.endpoints.filter((endpoint) => {
        return (endpoint[endpointFilterParam].toLowerCase().includes(name)) || (endpoint.id.toString().includes(name));
      });
      const newComp = { ...company };
      newComp.endpoints = filteredEndpoints;
      return newComp;
    });
    return final;
  }
}
