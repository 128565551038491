import { Component, OnInit, Inject, AfterViewInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import {
  AuthService,
  DataManagerService,
  NetworkService,
  ScanProgressService,
} from './core/services';
import { IScanState } from '@core/interfaces';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  loaderText = 'Please wait...';
  scanState: IScanState;
  showScanProgressBanner = true;
  partner: {
    name: string;
    title: string
    useTitle: number;
    loginLogo: string;
    headerLogo: string;
    favicon: string;
  } = {
      name: 'test2',
      title: '',
      useTitle: 0,
      loginLogo: '/assets/img/logo.svg',
      headerLogo: '/assets/img/logo.svg',
      favicon: '',
    };
  domainVerified = false;

  constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private dataManager: DataManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private scanProgressService: ScanProgressService,
    private networkService: NetworkService,
    private titleService: Title,
    private renderer: Renderer2,
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof ActivationEnd) {
        this.showScanProgressBanner = !this.route.snapshot.firstChild.data.hideScanProgress;
      }
    });

    this.scanProgressService.scanState$
      .subscribe((res) => {
        this.scanState = res;
      });
  }

  ngOnInit() {
    this.getPartnerPublicInfo();
    this.networkService.createOnline();
  }

  ngAfterViewInit() {
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  isLogin(): boolean {
    return this.router.url === '/login';
  }

  capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private getPartnerPublicInfo(): void {
    this.authService.getPartnerPublicInfo()
      .subscribe(resp => {
        this.domainVerified = true;
        this.partner = resp.data;
        if (this.partner.favicon) {
          this._document.getElementById('appFavicon').setAttribute('href', this.partner.favicon);
        }
        const title = this.partner.useTitle ? this.partner.title : this.capitalize(this.partner.name);
        this.titleService.setTitle(title + ' Ad Exchange');
      });
  }

}
