import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NzModalService } from 'ng-zorro-antd/modal';

import { ScannerStatisticFilters, SelectItem, ResponsePixalataReport, Scanners, RequestData } from '../../interfaces';
import { AppOptionsService } from '../app-options';
import { NotificationService } from '../notification';
import { NotificationComponent } from '@shared/components';
import { AuthService } from '../auth';

@Injectable({
  providedIn: 'root'
})
export class ScannerStatisticService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private router: Router,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
    private modalService: NzModalService,
    private authService: AuthService,
  ) {
    this.apiUrl = this.options.getApiUrl();
  }

  public get getScanners(): Scanners[] {
    return [
      { id: 'pixalate_prebid', name: 'Pixalate Pre-bid', active: false },
      { id: 'pixalate_postbid', name: 'Pixalate Post-bid', active: true },
      { id: 'pm_prebid', name: 'Protected Media Pre-bid', active: true },
      { id: 'pm_postbid', name: 'Protected Media Post-bid', active: true },
      { id: 'botman_postbid', name: 'BotMan Post-bid', active: true },
      { id: 'botman_prebid', name: 'BotMan Pre-bid', active: true },
      { id: 'tmt', name: 'TMT', active: true },
      { id: 'forensiq', name: 'Forensiq', active: true },
      { id: 'geoedge', name: 'GeoEdge', active: true },
    ];
  }


  getSelectAttrItems(): Observable<{ data: SelectItem[] }> {
    return of({
      data: [
        { id: 'endpoint_name', name: 'SSP', active: false, },
        { id: 'dsp_name', name: 'DSP', active: false, },
        { id: 'inventory_key', name: 'Domain/Bundle', active: false, },
        { id: 'reason_name', name: 'Block reason', active: false, },
      ]
    });
  }

  getSelectMetricItems(): Observable<{ data: SelectItem[] }> {
    return of({
      data: [
        { id: 'blocked', name: 'Blocked requests', active: false, },
      ]
    });
  }

  getBlockReason(): Observable<ScannerStatisticFilters> {
    return this.http.get<ScannerStatisticFilters>(`${this.apiUrl}/filters_config/sample_logger`)
      .pipe(catchError(this.processError));
  }

  sendReport(filterData: Partial<RequestData>): Observable<ResponsePixalataReport> {
    return this.http.post<ResponsePixalataReport>(`${this.apiUrl}/statistic/scanner/prebid`, filterData)
      .pipe(catchError(this.processError));
  }

  exportReport(filterData: Partial<RequestData>): Observable<{ url: string }> {
    return this.http.post<{ url: string }>(`${this.apiUrl}/statistic/scanner/prebid/export`, filterData)
      .pipe(catchError(this.processError));
  }

  handleError(error: { code: number, messages: string[] }) {

    if (error.code === 0) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }

    if (error.code === 400) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }
    if (error.code === 403) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }
    if (error.code === 401) {
      const errorMessage = this.authService.isExpired(this.authService.expiredDate)
        ? 'Your authentication token has been expired. Please log in again to continue using the platform'
        : error.messages.join('\r\n');
      this.showNotification('error', 'error', 'error', errorMessage, null);
      this.modalService.closeAll();
      void this.router.navigateByUrl('/login');
      return false;
    }

    if (error.code === 404) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }

    if (error.code === 422) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), '400px');
      return false;
    }

    if (error.code === 500) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      void this.router.navigate(['network']);
      return false;
    }

    if (error.code === 503) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }

  }

  private processError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return _throw({ code: 0, messages: ['Application crashed. Please try to refresh the page.'] });
    }
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,

    if (error.status === 400) {
      const messages = [];
      if (error.error.errors) {
        for (const i in error.error.errors) {
          messages.push(error.error.errors[i]);
        }
      }
      return _throw({ code: 400, messages });
    }

    if (error.status === 401) {
      return _throw({ code: 401, messages: ['Unauthorized. Please log in'] });
    }
    if (error.status === 403) {
      return _throw({ code: 403, messages: ['Action is not allowed'] });
    }
    if (error.status === 404) {
      return _throw({ code: 404, messages: ['Can\'t process the request. Requested URL not found.'] });
    }

    if (error.status === 422) {
      const errors = error.error.errors;
      const messages = errors ? Object.values(errors).map((item) => item) : [];
      return _throw({ code: 422, messages, errors });
    }

    if (error.status === 500) {
      return _throw({ code: 500, messages: ['Internal server error'] });
    }

    if (error.status === 503) {
      return _throw({ code: 503, messages: ['Service Unavailable'] });
    }
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
    width: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data, nzStyle: { width: width ?? '385px' } });
  }
}

