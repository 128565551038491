import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectSearchBilling',
})
export class SelectSearchBillingPipe implements PipeTransform {

  transform(value: any[], filter: string, valueAttr: string, labelAttr: string): any[] {
    if (!filter.trim()) {
      return value;
    }

    filter = filter.replace(/\s/g, '');

    const filteringObject = JSON.parse(value as any);
    const filteredValue = [];

    for (const key in filteringObject) {

      if (!filteringObject.hasOwnProperty(key)) {
        continue;
      }
      if (filteringObject[key].hasOwnProperty('subitems') && filteringObject[key].subitems.length > 0) {
        filteringObject[key].subitems = filteringObject[key].subitems.filter((subValue: { [x: string]: string; }) => {
          return subValue[labelAttr].toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });

        const itemContainsAcceptableSubItems = filteringObject[key].subitems.length > 0,
          itemContainsAcceptableLabelAttr = filteringObject[key][labelAttr].toLowerCase().indexOf(filter.toLowerCase()) > -1;

        if (itemContainsAcceptableSubItems || itemContainsAcceptableLabelAttr) {
          filteredValue.push(filteringObject[key]);
        }
        continue;
      }

      if (filteringObject[key][labelAttr].toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        filteredValue.push(filteringObject[key]);
      }
    }
    return filteredValue;
  }

}
