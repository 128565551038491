<!-- eslint-disable max-len -->
<header class="header clearfix">
  <div class="header__logo">
    <a routerLink="/">
      <img [src]="partner.headerLogo" alt="Ad Exchange" class="img-responsive">
    </a>
  </div>
  <nav class="header__nav">
    <ul *ngIf="getRole() !== 'external'">
      <li [class.active]="selectedMenu === 'main'"><a routerLink="/">Main</a></li>
      <li
        [class.active]="selectedMenu === 'statistics'
         || selectedMenu === 'statistics/dsp' || selectedMenu === 'statistics/responses' || selectedMenu === 'presets'">
        <a class="stat-head-link">Statistics</a>
        <div class="dropdown">
          <a routerLink="/statistics/reports">Statistics</a>
          <a routerLink="/dropped-requests">Dropped Req/Res Report</a>
          <a routerLink="/statistics/scanner-statistic">Scanners Statistics</a>
        </div>
        <img class="arrow" src="assets/img/arrow-icon.svg" alt="">
      </li>
      <li [class.active]="selectedMenu === 'billing'"><a routerLink="/billing">Billing</a></li>
      <li [class.active]="selectedMenu === 'companies'"><a routerLink="/companies">Companies</a></li>
      <li [class.active]="selectedMenu === 'traffic-logger' || selectedMenu === 'samples-logger'">
        <a class="stat-head-link" routerLink="/traffic-logger">Loggers</a>
        <div class="dropdown">
          <a routerLink="/traffic-logger">Incoming Traffic Logger</a>
          <a routerLink="/samples-logger">Req/Res Samples Logger</a>
        </div>
        <img class="arrow" src="assets/img/arrow-icon.svg" alt="">
      </li>
      <li [class.active]="selectedMenu === 'list-manager'"><a routerLink="/list-manager">List Manager</a></li>
      <li [class.active]="selectedMenu === 'dashboards'">
        <a routerLink="/dashboards">Analytics Dashboards</a>
      </li>
      <li *ngIf="partner.is_traffic_analyzer"
        [class.active]="selectedMenu === 'traffic-analyzer/dashboard' || selectedMenu === 'traffic-analyzer/rule-manager'">
        <a class="stat-head-link">Traffic Analyzer</a>
        <div class="dropdown">
          <a routerLink="/traffic-analyzer/dashboard">Dashboard</a>
          <a routerLink="/traffic-analyzer/rule-manager">Rule Manager</a>
        </div>
        <img class="arrow" src="assets/img/arrow-icon.svg" alt="">
      </li>
    </ul>

    <ul *ngIf="getRole() === 'external'">
      <li [class.active]="true">
        <a routerLink="/statistics/reports">Statistics</a>
      </li>
    </ul>
  </nav>
  <div class="alerts-button">
    <button nz-button nzType="link" [disabled]="!alertsDrawerData.length">
      <nz-badge [nzDot]="false" [nzCount]="alertsCount" nzSize="small" (click)="openAlertsDrawer()">
        <span nz-icon nzType="bell" nzTheme="twotone" style="font-size: 22px;"></span>
      </nz-badge>
    </button>
  </div>
  <div *ngIf="userRole !== 'viewer'" class="header-feature">
    <a routerLink="/suggest-feature">
      <img src="assets/img/ic_suggest.svg" alt="">
    </a>
  </div>
  <div class="documentation-button" (click)="openedDocumentation()">
    <i nz-icon>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M5 13.18V17.18L12 21L19 17.18V13.18L12 17L5 13.18ZM12 3L1 9L12 15L21 10.09V17H23V9L12 3Z" fill="#0070D2" />
      </svg>
    </i>
  </div>
  <div class="user-button">
    <img src="assets/img/ic_account_circle.svg" alt="">
    <div class="user-menu">
      <a *ngIf="['admin', 'manager', 'support', 'viewer'].indexOf(getRole()) !== -1" routerLink="/settings">General
        Platform Settings</a>
      <a *ngIf="['admin', 'manager', 'support', 'viewer'].indexOf(getRole()) !== -1" routerLink="/frameworks">Frameworks
        and Regulations</a>
      <a *ngIf="['admin', 'manager', 'support', 'viewer'].indexOf(getRole()) !== -1"
        routerLink="/integrations">Integrations</a>
      <a *ngIf="['admin', 'manager', 'support', 'viewer'].indexOf(getRole()) !== -1"
        routerLink="/sellers-json">Sellers.json Manager</a>
      <a *ngIf="['admin', 'manager', 'support', 'viewer'].indexOf(getRole()) !== -1" routerLink="/scanners">Scanners</a>
      <a *ngIf="getRole() !== 'external'" routerLink="/users">User Management</a>
      <a *ngIf="getRole() !== 'external'" routerLink="/mismatches">Mismatches</a>
      <a *ngIf="isServiceDomain()" routerLink="/admin/partners">ADMIN</a>
      <a *ngIf="isServiceDomain()" routerLink="/admin/documentation/edit">Documentation Editor</a>
      <a href="#" (click)="logout($event)">Logout</a>
    </div>
  </div>
</header>