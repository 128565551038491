import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'endpointFilterWithoutCompanies',
})
export class EndpointFilterWithoutCompaniesPipe implements PipeTransform {

  transform(endpoints: any[], name: string, activity: number): any[] {
    if (activity > -1) {
      const filteredCompanies = endpoints.filter(endpoint => {
        return (endpoint.active == activity);
      });

      if (!filteredCompanies.length) {
        return [];
      }
    }

    if (!name || typeof name !== 'string' || name.length < 2)
      return endpoints;

    name = name.toLowerCase();
    const filteredCompanies = endpoints.filter(endpoint => {
      if ((~endpoint.name.toLowerCase().indexOf(name)) || (~endpoint.id.toString().indexOf(name))) {
        return true;
      }
    });

    if (filteredCompanies.length) {
      return filteredCompanies;
    }
    return [];
  }

}
