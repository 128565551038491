import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NzNotificationService } from 'ng-zorro-antd/notification';

import { Partner, User } from '../../interfaces';
import { environment } from '@environment/environment';
import { AuthService } from '../auth';
@Injectable()
export class CustomErrorHandlerService implements ErrorHandler {

  apiLink = environment.apiForLogger;
  errorObject: {
    userEmail?: string;
    userId?: number;
    partnerId?: number;
    partnerName?: string;
    errorMessage?: string;
    errorPlace?: string;
  };

  constructor(
    private ngZone: NgZone,
    private http: HttpClient,
    private notification: NzNotificationService,
    private router: Router,
    private authService: AuthService,
  ) { }

  handleError(error: Error) {
    this.ngZone.run(() => {
      if (error?.stack?.length) {
        const partner: Partner = JSON.parse(localStorage.getItem('partner'));
        const user: User = JSON.parse(localStorage.getItem('user-info'));
        const errorStacks = error.stack.split('\n');
        const placeError = errorStacks[1].split('(')[0];
        this.errorObject = {
          userEmail: user.email,
          userId: user.id,
          partnerId: partner.id,
          partnerName: partner.name,
          errorMessage: error.message,
          errorPlace: placeError.trim(),
        };

        const chunkErrorSubstring = 'Uncaught (in promise): ChunkLoadError: Loading chunk';
        const typeErrorSubstring = 'Uncaught (in promise): TypeError: Cannot read properties of undefined';
        const changeDetectionSubstring = 'NG0100: ExpressionChangedAfterItHasBeenCheckedError';

        if (error.message.includes(chunkErrorSubstring)) {
          return;
        } else if (error.message.includes(typeErrorSubstring)) {
          return;
        } else if (error.message.includes(changeDetectionSubstring)) {
          return;
        } else {
          this.sendError(this.errorObject)
            .subscribe(() => { },
              error => this.showError(error));
        }

      } else {
        this.errorObject = {};
        return;
      }
    });
  }

  private sendError(error: {
    userEmail?: string;
    userId?: number;
    partnerId?: number;
    partnerName?: string;
    errorMessage?: string;
    errorPlace?: string;
  }): Observable<any> {
    return this.http.post(this.apiLink, error)
      .pipe(catchError(this.processError));
  }

  private showError(error: { code: number, messages: string[] }) {

    if (error.code === 0) {
      this.notification.create('error', null, error.messages.join('<br/>'));
      return false;
    }

    if (error.code === 400) {
      this.notification.create('error', null, error.messages.join('<br/>'));
      return false;
    }
    if (error.code === 403) {
      this.notification.create('error', null, error.messages.join('<br/>'));
      return false;
    }
    if (error.code === 401) {
      const errorMessage = this.authService.isExpired(this.authService.expiredDate)
        ? 'Your authentication token has been expired. Please log in again to continue using the platform'
        : error.messages.join('<br/>');
      this.notification.create('error', null, errorMessage);
      void this.router.navigateByUrl('/login');
      return false;
    }

    if (error.code === 404) {
      this.notification.create('error', null, error.messages.join('<br/>'));
      return false;
    }

    if (error.code === 422) {
      this.notification.create('error', null, [...error.messages].join('<br/>'), { nzStyle: { width: '400px' } });
      return false;
    }

    if (error.code === 500) {
      this.notification.create('error', null, error.messages.join('<br/>'));
      void this.router.navigate(['network']);
      return false;
    }

  }

  private processError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return _throw({ code: 0, messages: ['Application crashed. Please try to refresh the page.'] });
    }
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,

    if (error.status === 400) {
      const messages = [];
      if (error.error['errors']) {
        for (const i in error.error['errors']) {
          messages.push(error.error['errors'][i].join());
        }
      }
      return _throw({ code: 400, messages });
    }

    if (error.status === 401) {
      return _throw({ code: 401, messages: ['Unauthorized. Please log in'] });
    }
    if (error.status === 403) {
      return _throw({ code: 403, messages: ['Action is not allowed'] });
    }
    if (error.status === 404) {
      return _throw({ code: 404, messages: ['Can\'t process the request. Requested URL not found.'] });
    }
    if (error.status === 422) {
      return _throw({ code: 424, messages: ['The filter list name must be unique.'] });
    }

    if (error.status === 500) {
      return _throw({ code: 500, messages: ['Internal server error'] });
    }

    if (error.status === 503) {
      return _throw({ code: 503, messages: ['Service Unavailable'] });
    }
  }
}
