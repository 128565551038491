/* eslint-disable max-len */
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, EventEmitter, Output, AfterContentInit, OnInit, ViewChild } from '@angular/core';

import { FilterListInterface } from '@core/interfaces';
import { findDifferentBetweenCompaniesArrays } from '@core/helpers/array-difference/array-difference';
import { NotificationService } from '@core/services';
import { NotificationComponent } from '../notification';

const toggleArrow = trigger('toggleArrow', [
  state('none', style({ transform: 'rotate(0)' })),
  state('visible', style({ transform: 'rotate(180deg)' })),
  transition('visible => none', animate('200ms ease-in')),
  transition('none => visible', animate('200ms ease-out')),
]);

@Component({
  selector: 'app-checkbox-companies-group',
  templateUrl: './checkbox-companies-group.component.html',
  styleUrls: ['./checkbox-companies-group.component.scss'],
  animations: [toggleArrow]
})
export class CheckboxCompaniesGroupComponent implements OnInit, AfterContentInit {
  @Input() companies: FilterListInterface.CompaniesDataGeneral[];

  @Input() public set allChecked(value: boolean | number) {
    if (typeof value === 'number') {
      this._allChecked = !!value;
    } else {
      this._allChecked = value;
    }
  }

  public get allChecked(): boolean {
    return this._allChecked;
  }

  @Input() public set isAllDisabled(value: boolean) {
    this._isAllDisabled = value;
  }

  public get isAllDisabled(): boolean {
    return this._isAllDisabled;
  }

  @Input() public set type(value: string) {
    this._type = value;
  }

  public get type(): string {
    return this._type;
  }

  @Output() changedItems = new EventEmitter<{ items: number[], isAll: boolean, hasChanges: boolean }>();
  initArray: FilterListInterface.CompaniesDataGeneral[] = [];
  changedArray: FilterListInterface.CompaniesDataGeneral[] = [];
  searchValue: string;
  listHeight = 'auto';
  listMaxHeight = '800px';
  companyChecked: number[] = [];
  outputData: { items: number[], isAll: boolean, hasChanges: boolean } = { items: [], isAll: false, hasChanges: false };
  companyState: { [key: number]: boolean } = {};

  private _isAllDisabled = false;
  private _type = 'filterList';
  private _allChecked = false;

  constructor(
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
  ) { }

  ngOnInit(): void {
    this.initArray = JSON.parse(JSON.stringify(this.companies));
    this.changedArray = JSON.parse(JSON.stringify(this.companies));
  }

  ngAfterContentInit(): void {
    this.listHeight = window.innerHeight - 280 + 'px';
    window.addEventListener('resize', () => {
      this.listHeight = window.innerHeight - 280 + 'px';
    });
  }


  toggleState(companyId: number): void {
    this.companyState[companyId] = !this.companyState[companyId];
  }

  isClosed(companyId: number): 'visible' | 'none' {
    if (this.companyState[companyId]) {
      return 'visible';
    }

    return 'none';
  }

  updateAllChecked(): void {
    const checked = this.allChecked;
    this.companies.forEach(company => {
      company.checked = checked;
      company.indeterminate = false;
      company.disabled = checked;
      company.endpoints.forEach(endpoint => {
        endpoint.checked = checked;
        endpoint.disabled = checked;
      });
    });


    this.companyChecked = this.companies.flatMap(company => {
      return company.endpoints.filter(endpoint => endpoint.checked).map(endpoint => endpoint.value);
    });

    this.companyChecked = this.allChecked ? [] : this.companyChecked;

    this.outputData = {
      isAll: this.allChecked,
      items: this.companyChecked,
      hasChanges: findDifferentBetweenCompaniesArrays(this.companies, this.initArray).length > 0 ? true : false
    };

    this.changedItems.emit(this.outputData);
  }

  updateAllEndpointByCompanyChecked(_: boolean, company: FilterListInterface.CompaniesDataGeneral): void {
    if (company.checked) {
      company.endpoints = company.endpoints.map(endpoint => ({
        ...endpoint,
        checked: true,
      }));
    } else {
      company.endpoints = company.endpoints.map(endpoint => ({
        ...endpoint,
        checked: false,
      }));
    }

    this.companies = this.updateCompaniesAfterChecked(this.companies, company);

    this.companyChecked = this.companies.flatMap(company => {
      return company.endpoints.filter(endpoint => endpoint.checked).map(endpoint => endpoint.value);
    });

    this.allChecked = this.isEveryElementChecked(this.companies);

    this.changeDisabledState();

    this.companyChecked = this.allChecked ? [] : this.companyChecked;

    this.isAllDisabled = this.companyChecked.length === 30;

    this.outputData = {
      isAll: this.allChecked,
      items: this.companyChecked.filter((element, index) => index < 30),
      hasChanges: findDifferentBetweenCompaniesArrays(this.companies, this.initArray).length > 0 ? true : false
    };

    this.changedItems.emit(this.outputData);
  }

  updateSingleEndpointByCompanyChecked(_: boolean, company: FilterListInterface.CompaniesDataGeneral, itemEndpoint: FilterListInterface.EndpointsGeneral): void {
    this.changedArray = this.changeArray(this.companies, itemEndpoint, company);
    const changedCompany = this.changedArray.find(element => element.id === company.id);
    if (company.endpoints.every(endpoint => !endpoint.checked) && changedCompany.endpoints.every(endpoint => !endpoint.checked)) {
      company.checked = false;
      company.indeterminate = false;
    } else if (company.endpoints.every(endpoint => endpoint.checked) && changedCompany.endpoints.every(endpoint => endpoint.checked)) {
      company.checked = true;
      company.indeterminate = false;
    } else {
      company.checked = false;
      company.indeterminate = true;
    }

    this.allChecked = this.isEveryElementChecked(this.companies);

    this.companyChecked = this.companies.flatMap(company => {
      return company.endpoints.filter(endpoint => endpoint.checked).map(endpoint => endpoint.value);
    });

    this.changeDisabledState();

    this.companyChecked = this.allChecked ? [] : this.companyChecked;

    this.isAllDisabled = this.companyChecked.length === 30;

    this.outputData = {
      isAll: this.allChecked,
      items: this.companyChecked.filter((element, index) => index < 30),
      hasChanges: findDifferentBetweenCompaniesArrays(this.companies, this.initArray).length > 0 ? true : false
    };

    this.changedItems.emit(this.outputData);
  }

  onSearchInput(event: Event): void {
    this.searchValue = (event.target as HTMLInputElement).value;
  }

  private isEveryElementChecked(array: FilterListInterface.CompaniesDataGeneral[]): boolean {
    for (const element of array) {
      if (!element.checked) {
        return false;
      }

      for (const endpoint of element.endpoints) {
        if (!endpoint.checked) {
          return false;
        }
      }
    }

    return true;
  }

  private changeArray(array: FilterListInterface.CompaniesDataGeneral[],
    checkedEndpoint: FilterListInterface.EndpointsGeneral,
    company: FilterListInterface.CompaniesDataGeneral): FilterListInterface.CompaniesDataGeneral[] {
    let changedArray = [];
    changedArray = array.map(element => {
      if (element.id === company.id) {
        element.endpoints = element.endpoints.map(endpoint => {
          if (endpoint.id === checkedEndpoint.id) {
            endpoint.checked = checkedEndpoint.checked;
          }
          return endpoint;
        });
      }
      return element;
    });

    return changedArray;
  }

  private updateCompaniesAfterChecked(array: FilterListInterface.CompaniesDataGeneral[],
    company: FilterListInterface.CompaniesDataGeneral): FilterListInterface.CompaniesDataGeneral[] {
    const companyChecked = this.companies.flatMap(company => {
      return company.endpoints.filter(endpoint => endpoint.checked).map(endpoint => endpoint.value);
    });

    let updatedArray: FilterListInterface.CompaniesDataGeneral[] = [];
    updatedArray = array.map(element => {
      if (element.id === company.id && companyChecked.length < 30) {
        element.indeterminate = false;
        element.checked = company.checked;
        element.endpoints = element.endpoints.map(item => {
          const checkedEndpoint = company.endpoints.find(endpoint => endpoint.checked);
          if (item.id === checkedEndpoint?.id) {
            item.checked = checkedEndpoint.checked;
          }

          return item;
        });
      } else if (element.id === company.id && companyChecked.length >= 30) {
        element.indeterminate = true;
        element.checked = false;
        element.disabled = true;
        element.endpoints = element.endpoints.map(item => {
          const checkedEndpoint = company.endpoints.find(endpoint => endpoint.checked);
          if (item.id === checkedEndpoint?.id) {
            item.checked = checkedEndpoint.checked;
          }

          return item;
        });
      }
      return element;
    });

    return updatedArray;
  }

  private changeDisabledState(): void {
    let originalArray = [];
    originalArray = [...this.companyChecked];
    const changedArray = originalArray.filter((element, index) => index < 30);
    if (this.companyChecked.length >= 30) {
      this.companies = this.companies.map(company => {
        company.disabled = true;
        let allChecked = true;
        let anyChecked = false;

        company.endpoints = company.endpoints.map(endpoint => {
          const isChanged = changedArray.includes(endpoint.id);
          endpoint.disabled = !isChanged;
          endpoint.checked = isChanged && endpoint.checked;

          if (isChanged) {
            company.disabled = false;
          }
          if (!endpoint.checked) {
            allChecked = false;
          }
          if (endpoint.checked) {
            anyChecked = true;
          }

          if (allChecked) {
            company.disabled = true;
          }

          return endpoint;
        });

        if (allChecked) {
          company.checked = true;
          company.indeterminate = false;
        } else if (anyChecked) {
          company.indeterminate = true;
          company.disabled = true;
        } else {
          company.checked = false;
          company.indeterminate = false;
        }

        return company;
      });


      this.showNotification(
        'error',
        'error',
        'error',
        this.type === 'ruleManager'
          ? FilterListInterface.notificationRestrictedText.ruleManager
          : FilterListInterface.notificationRestrictedText.filterList,
        '400px',
      );
    } else {
      this.companies.forEach(company => {
        company.disabled = false;
        company.endpoints.forEach(endpoint => endpoint.disabled = false);
      });
    }
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
    width: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data, nzStyle: { width: width ?? '385px' }, nzDuration: 15000 });
  }
}
