/* eslint-disable max-len */
import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';


import { NotificationComponent } from '../notification';

import { FilterListService, NotificationService } from '@core/services';
import { FilterListInterface } from '@core/interfaces';
import { PaginatorModel } from '@app/core/interfaces/filter-list/filter-list.interface';
import { findDifferentBetweenEndpointsArrays, compareEndpointsArray, rearrangeArrayEndpoints } from '@core/helpers';
@Component({
  selector: 'app-select-checkbox',
  templateUrl: './select-checkbox.component.html',
  styleUrls: ['./select-checkbox.component.scss']
})
export class SelectCheckboxComponent implements OnInit, AfterContentInit {

  endpoints: FilterListInterface.EndpointsGeneral[] = [];
  @Input() allChecked = false;

  @Input() control: FormControl = new FormControl();

  @Input() public set isDisabledAll(value: boolean) {
    this._isDisabledAll = value;
  }

  public get isDisabledAll(): boolean {
    return this._isDisabledAll;
  }

  @Input() public set idsArray(value: number[]) {
    this._idsArray = value;
    this.endpoints = compareEndpointsArray(this.endpoints, this.idsArray, this.allChecked);
  }

  public get idsArray(): number[] {
    return this._idsArray;
  }

  @Input() public set arrayForSearch(value: FilterListInterface.EndpointsGeneral[]) {
    this._arrayForSearch = value;
    if (this.endpoints.every(endpoint => !endpoint.checked) && this.arrayForSearch.every(endpoint => !endpoint.checked)) {
      this.allChecked = false;
    } else if (this.endpoints.every(endpoint => endpoint.checked) && this.arrayForSearch.every(endpoint => endpoint.checked)) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  public get arrayForSearch(): FilterListInterface.EndpointsGeneral[] {
    return this._arrayForSearch;
  }

  @Input() public set checkedIdsArray(value: number[]) {
    this._checkedIdsArray = value;
  }

  public get checkedIdsArray(): number[] {
    return this._checkedIdsArray;

  }
  @Input() public set side(value: string) {
    this._side = value;
  }

  public get side(): string {
    return this._side;
  }

  @Input() public set type(value: string) {
    this._type = value;
  }

  public get type(): string {
    return this._type;
  }


  @Input() id: number = null;
  @Output() changedItems = new EventEmitter<{
    items: number[],
    isAll: boolean,
    hasChanges: boolean,
    checkedEndpoint?: { [key: string]: any }
    isDisabledItems?: boolean,
  }>();
  searchValue: string;
  initArray: FilterListInterface.EndpointsGeneral[] = [];
  paginationState: FilterListInterface.PaginationState<any> = new PaginatorModel();
  checkedItems: number[] = [];
  outputData: {
    isAll: boolean,
    items: number[],
    hasChanges: boolean,
    checkedEndpoint?: { [key: string]: any }
    isDisabledItems?: boolean,
  } = { isAll: false, items: [], hasChanges: false };
  listHeight = 'auto';
  listMaxHeight = '800px';

  private _arrayForSearch = [];
  private timer: number;
  private _type = 'filterList';


  params = {
    page: 1,
    per_page: 35,
    ssp_name: '',
    dsp_name: '',
    side: '',
    id: null,
    checkedEndpoints: null,
  };

  private _isDisabledAll = false;
  private _idsArray: number[] = [];
  private _checkedIdsArray: number[] = [];
  private _side = 'ssp';

  constructor(
    private filterListService: FilterListService,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
  ) { }


  ngOnInit(): void {
    this.params.side = this.side;
    this.params.id = this.id;
    this.getEndpoints(this.params);
  }

  ngAfterContentInit(): void {
    this.listHeight = window.innerHeight - 280 + 'px';
    window.addEventListener('resize', () => {
      this.listHeight = window.innerHeight - 280 + 'px';
    });
  }

  updateAllChecked(): void {
    if (this.allChecked) {
      this.endpoints = this.endpoints.map(endpoint => ({
        ...endpoint,
        checked: true,
        disabled: true,
      }));
    } else {
      this.endpoints = this.endpoints.map(endpoint => ({
        ...endpoint,
        checked: false,
        disabled: false,
      }));
    }

    this.checkedItems = this.endpoints.filter(endpoint => endpoint.checked).map(endpoint => endpoint['value']);

    this.checkedIdsArray = this.checkedItems;

    this.params.checkedEndpoints = this.checkedIdsArray;

    this.checkedItems = this.allChecked ? [] : this.checkedItems;

    this.outputData = {
      isAll: this.allChecked,
      items: this.checkedItems,
      hasChanges: findDifferentBetweenEndpointsArrays(this.endpoints, this.initArray).length > 0 ? true : false,
      isDisabledItems: this.allChecked,
    };

    this.changedItems.emit(this.outputData);
  }

  updateSingleChecked(_: boolean, endpointChecked: FilterListInterface.EndpointsGeneral): void {
    if (endpointChecked.checked) {
      this.checkedIdsArray.push(endpointChecked.id);
    } else {
      this.checkedIdsArray = this.checkedIdsArray.filter(id => id !== endpointChecked.id);
    }

    const checkedIdsArray = new Set(this.checkedIdsArray);

    this.checkedIdsArray = Array.from(checkedIdsArray);


    this.params.checkedEndpoints = this.checkedIdsArray;

    this.checkedItems = this.endpoints.filter(endpoint => endpoint.checked).map(endpoint => endpoint['value']);

    this.changeDisabledState();

    this.isDisabledAll = this.checkedItems.length === 30;

    this.checkedItems = this.allChecked ? [] : this.checkedItems;

    this.outputData = {
      isAll: this.allChecked,
      items: this.checkedItems,
      hasChanges: findDifferentBetweenEndpointsArrays(this.endpoints, this.initArray).length > 0 ? true : false,
      checkedEndpoint: endpointChecked,
    };

    this.changedItems.emit(this.outputData);
  }

  onSearchInput(event: Event, to = false): void {
    const propertyName = this.side + '_name';
    this.params[propertyName] = (event.target as HTMLInputElement).value;
    this.params.page = 1;

    if (this.params[propertyName].length) {
      this.params.checkedEndpoints = null;
    } else {
      this.params.checkedEndpoints = this.checkedIdsArray;
    }

    if (to) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.endpoints = [];
        this.getEndpoints(this.params);
      }, 600);
    } else {
      this.endpoints = [];
      this.getEndpoints(this.params);
    }


  }

  onScroll(): void {
    if (this.paginationState.next_page_url !== null) {
      this.params.page = ++this.paginationState.current_page;
      this.getEndpoints(this.params);
    }
  }


  private getEndpoints(params: any): void {
    this.filterListService.getEndpointsNew(this.filterNormalize(params))
      .subscribe(res => {
        const { data, ...rest } = res[this.side];
        this.paginationState = { ...rest };
        const changedEndpoints = data.map(item => {
          return { checked: false, tooltip: item.full_name, label: item.full_name, value: item.id, ...item };
        });

        this.endpoints = this.endpoints.concat(changedEndpoints);

        this.endpoints = this.modifyEndpoints(this.endpoints, this.checkedIdsArray, this.allChecked);

        if (Array.isArray(this.params.checkedEndpoints)) {
          this.isDisabledAll = this.params.checkedEndpoints.length === 30;
        }


        this.initArray = JSON.parse(JSON.stringify(this.endpoints));
      });
  }

  private filterNormalize(filter: any) {
    const params = Object.assign({}, filter);

    for (const key in params) {
      if (params[key] === '' || params[key] === null) {
        delete params[key];
      }
    }

    return params;
  }

  private changeDisabledState(): void {
    if (this.checkedIdsArray.length === 30) {
      this.endpoints.forEach(endpoint => {
        if (!this.checkedIdsArray.includes(endpoint.id)) {
          endpoint.disabled = true;
        } else {
          endpoint.disabled = false;
        }
      });

      this.showNotification(
        'error',
        'error',
        'error',
        this.type === 'ruleManager'
          ? FilterListInterface.notificationRestrictedText.ruleManager
          : FilterListInterface.notificationRestrictedText.filterList,
        '400px',
      );

    } else {
      this.endpoints.forEach(endpoint => endpoint.disabled = false);
    }
  }


  private modifyEndpoints(
    inputArray: FilterListInterface.EndpointsGeneral[],
    inputIdsArray: number[],
    isAll: boolean,
  ): FilterListInterface.EndpointsGeneral[] {
    const uniqueEndpointsMap = new Map<number, FilterListInterface.EndpointsGeneral>();

    const comparedEndpointsArray = compareEndpointsArray(inputArray, inputIdsArray, isAll);

    const rearrangedEndpoints = rearrangeArrayEndpoints(comparedEndpointsArray, inputIdsArray);

    rearrangedEndpoints.forEach((endpoint) => {
      uniqueEndpointsMap.set(endpoint.id, endpoint);
    });

    const outputEndpointsArray = Array.from(uniqueEndpointsMap.values());

    return outputEndpointsArray;
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
    width: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data, nzStyle: { width: width ?? '385px' }, nzDuration: 15000 });
  }
}
