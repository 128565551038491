import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of, throwError as _throw, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NzModalService } from 'ng-zorro-antd/modal';

import { FieldsData, Preset, PresetTableResponse } from './../../interfaces';
import { AuthService } from '../auth';
import { AppOptionsService } from '../app-options';
import { NotificationService } from '../notification';
import { NotificationComponent } from '@shared/components';

@Injectable({
  providedIn: 'root'
})
export class ReportDataManagerService {

  private apiURL: string;

  public statistic: any;
  public preset: Preset;
  selectedPreset = new BehaviorSubject(null);
  selectPresetObs = this.selectedPreset.asObservable();

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private router: Router,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
    private modalService: NzModalService,
    private authService: AuthService,
  ) {
    this.apiURL = this.options.getApiUrl();
  }

  get presets(): Observable<{ data: Preset[] }> {
    return this.http.get<{ data: Preset[] }>(`${this.apiURL}/presets`);
  }

  get template(): Observable<{ data: Preset }> {
    return this.http.get<{ data: Preset }>(`${this.apiURL}/presets/create`);
  }

  presetTable(preset): Observable<{ data: { id: number; exists: boolean } }> {
    return this.http.post<{ data: { id: number; exists: boolean } }>(`${this.apiURL}/presets/statistic/exists`, preset)
      .pipe(catchError(this.processError));
  }

  items(presetId) {
    return this.http.get(`${this.apiURL}/presets/items/${presetId}`)
      .pipe(catchError(this.processError));
  }

  delete(id): Observable<{ data: Preset[] }> {
    return this.http.delete<{ data: Preset[] }>(`${this.apiURL}/presets/${id}`);
  }

  store(preset): Observable<{ data: Preset[] }> {
    return this.http.post<{ data: Preset[] }>(`${this.apiURL}/presets`, preset);
  }

  update(preset): Observable<{ data: Preset[] }> {
    return this.http.patch<{ data: Preset[] }>(`${this.apiURL}/presets/${preset.id}`, preset);
  }

  export(data) {
    return this.http.post(`${this.apiURL}/presets/statistic/export`, data)
      .pipe(catchError(this.processError));
  }

  show(id: number) {
    return this.http.get(`${this.apiURL}/presets/${id}`);
  }

  getTableData(data): Observable<any> {
    return this.http.post(`${this.apiURL}/presets/statistic`, data).pipe(catchError(this.processError));
  }

  getTableFields(): Observable<{ data: FieldsData[] }> {
    return of({
      data: [
        { attr: 'ssp_requests', label: 'SSP Requests', active: true, disabled: false, value: 'metric' },
        { attr: 'requests', label: 'Bid Requests', active: true, disabled: false, value: 'metric' },
        { attr: 'responses', label: 'DSP Responses', active: true, disabled: false, value: 'metric' },
        { attr: 'wins', label: 'DSP Wins', active: true, disabled: false, value: 'metric' },
        { attr: 'dsp_win_rate', label: 'DSP Win Rate, %', active: true, disabled: false, value: 'metric' },
        { attr: 'ssp_wins', label: 'SSP Wins', active: true, disabled: false, value: 'metric' },
        { attr: 'ssp_win_rate', label: 'SSP Win Rate, %', active: true, disabled: false, value: 'metric' },
        { attr: 'imps', label: 'Impressions', active: true, disabled: false, value: 'metric' },
        { attr: 'fill_rate', label: 'Fill rate, %', active: true, disabled: false, value: 'metric' },
        { attr: 'render_rate', label: 'Render Rate, %', active: true, disabled: false, value: 'metric' },
        { attr: 'ssp_price', label: 'SSP Spend', active: true, disabled: false, value: 'metric' },
        { attr: 'ssp_ecpm', label: 'SSP eCPM', active: true, disabled: false, value: 'metric' },
        { attr: 'ssp_rcpm', label: 'SSP RCPM', active: true, disabled: false, value: 'metric' },
        { attr: 'avg_ssp_bid_floor', label: 'Avg. SSP Bidfloor', active: true, disabled: false, value: 'metric' },
        { attr: 'avg_ssp_bid_price', label: 'Avg. SSP Bid Price', active: true, disabled: false, value: 'metric' },
        { attr: 'dsp_price', label: 'DSP Spend', active: true, disabled: false, value: 'metric' },
        { attr: 'dsp_ecpm', label: 'DSP eCPM', active: true, disabled: false, value: 'metric' },
        { attr: 'dsp_rcpm', label: 'DSP RCPM', active: true, disabled: false, value: 'metric' },
        { attr: 'avg_dsp_bid_floor', label: 'Avg. DSP Bidfloor', active: true, disabled: false, value: 'metric' },
        { attr: 'avg_dsp_bid_price', label: 'Avg. DSP Bid Price', active: true, disabled: false, value: 'metric' },
        { attr: 'platform_revenue', label: 'Profit', active: true, disabled: false, value: 'metric' },
        { attr: 'bid_rate', label: 'Valid Bid Rate, %', active: true, disabled: false, value: 'metric' },
        { attr: 'total_bid_rate', label: 'Total Bid Rate, %', active: true, disabled: false, value: 'metric' },
        { attr: 'timeout_rate', label: 'Timeout rate, %', active: true, disabled: false, value: 'metric' },
        { attr: 'sspName', label: 'SSP Endpoint', active: true, disabled: false, value: 'attribute' },
        { attr: 'sspCompanyName', label: 'SSP Company', active: true, disabled: false, value: 'attribute' },
        { attr: 'sspEndpointType', label: 'SSP Endpoint Type', active: true, disabled: false, value: 'attribute' },
        { attr: 'dspName', label: 'DSP Endpoint', active: true, disabled: false, value: 'attribute' },
        { attr: 'dspCompanyName', label: 'DSP Company', active: true, disabled: false, value: 'attribute' },
        { attr: 'dspEndpointType', label: 'DSP Endpoint Type', active: true, disabled: false, value: 'attribute' },
        { attr: 'geoName', label: 'GEO', active: true, disabled: false, value: 'attribute' },
        { attr: 'geoName', label: 'GEO', active: true, disabled: false, value: 'attribute' },
        { attr: 'platformName', label: 'Traffic Type', active: true, disabled: false, value: 'attribute' },
        { attr: 'trafficName', label: 'Ad Format', active: true, disabled: false, value: 'attribute' },
        { attr: 'size', label: 'Size', active: true, disabled: false, value: 'attribute' },
        { attr: 'inventory_key', label: 'Domain/App Bundle', active: true, disabled: false, value: 'attribute' },
        { attr: 'publisher_id', label: 'Publisher ID', active: true, disabled: false, value: 'attribute' },
        { attr: 'crid', label: 'CRID', active: true, disabled: false, value: 'attribute' },
        { attr: 'osName', label: 'OS', active: true, disabled: false, value: 'attribute' },
        { attr: 'date_export', label: 'Date', active: true, disabled: false, value: 'attribute' },
      ]
    });
  }

  handleError(error: { code: number, messages: string[] }) {
    if (error.code === 0) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null, 0);
      return false;
    }

    if (error.code === 400) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null, 0);
      return false;
    }
    if (error.code === 403) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null, 0);
      return false;
    }
    if (error.code === 401) {
      const errorMessage = this.authService.isExpired(this.authService.expiredDate)
        ? 'Your authentication token has been expired. Please log in again to continue using the platform'
        : error.messages.join('\r\n');
      this.showNotification('error', 'error', 'error', errorMessage, null, 0);
      this.modalService.closeAll();
      void this.router.navigateByUrl('/login');
      return false;
    }

    if (error.code === 404) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null, 0);
      return false;
    }

    if (error.code === 422) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), '400px', null);
      return false;
    }

    if (error.code === 500) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null, 0);
      return false;
    }

    if (error.code === 503) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null, 0);
      return false;
    }

  }

  private processError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return _throw({ code: 0, messages: ['Application crashed. Please try to refresh the page.'] });
    }
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,

    if (error.status === 400) {
      const messages = [];
      if (error.error['errors']) {
        for (const i in error.error['errors']) {
          messages.push(error.error['errors'][i]);
        }
      }
      return _throw({ code: 400, messages });
    }

    if (error.status === 401) {
      return _throw({ code: 401, messages: ['Unauthorized. Please log in'] });
    }

    if (error.status === 403) {
      return _throw({ code: 403, messages: ['Action is not allowed'] });
    }

    if (error.status === 404) {
      return _throw({ code: 404, messages: ['Can\'t process the request. Requested URL not found.'] });
    }

    if (error.status === 422) {
      const errors = error.error['errors'];
      const messages = errors ? Object.values(errors).map((item) => item) : [];
      return _throw({ code: 422, messages });
    }

    if (error.status === 422) {
      const errors = error.error['errors'];
      const messages = errors ? Object.values(errors).map((item) => item) : [];
      return _throw({ code: 422, messages });
    }

    if (error.status === 500) {
      return _throw({ code: 500, messages: ['Internal server error'] });
    }

    if (error.status === 503) {
      return _throw({ code: 503, messages: ['Service Unavailable'] });
    }
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
    width: string,
    duration: number,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data, nzStyle: { width: width ?? '385px' }, nzDuration: duration ?? 4500 });
  }
}

