import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Timezones } from '@core/constants';

@Component({
  selector: 'app-timezone-control',
  templateUrl: './timezone-control.component.html',
  styleUrls: ['./timezone-control.component.scss']
})
export class TimezoneControlComponent implements OnInit {
  @Input() control: FormControl = new FormControl();
  @Output() changeTimeZone = new EventEmitter<string>();
  timezoneList: { id: string; name: string }[] = [];
  constructor() {
  }

  ngOnInit(): void {
    this.buildTZ();
  }

  onTZUpdated(e: string): void {
    this.changeTimeZone.emit(this.control.value);
  }

  private buildTZ(): void {
    for (const id in Timezones) {
      this.timezoneList.push({ id: id, name: Timezones[id] });
    }
  }
}
