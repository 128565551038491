import { Pipe, PipeTransform } from '@angular/core';
import { ScannersEndpoints } from '@core/models';

@Pipe({
  name: 'endpointFilter',
})
export class EndpointFilterPipe implements PipeTransform {
  transform(companies: any[], name: string, type: string, activity: number): any[] {
    if (activity > -1 && companies) {
      const filteredByEndpoint = companies.filter(company => {
        const side = type + '_settings';
        let hasEndpoint = false;
        const endp = [];

        for (const indx in company[side]) {
          if (company[side as keyof object][indx].active === activity) {
            hasEndpoint = true;
            endp.push(company[side][indx]);
          }
        }

        company[type + '_settings'] = endp;

        if (hasEndpoint) {
          return true;
        }
      });
    }

    if (!name || typeof name !== 'string' || name.length < 2) {
      return companies;
    }

    name = name.toLowerCase();

    // let endpointFilterParam = (type == 'ssp' ? 'name' : 'endpoint');
    const endpointFilterParam = 'name';
    const filteredCompanies = companies.filter(company => {
      if (~company.name.toLowerCase().indexOf(name)) {
        return true;
      }
    });

    if (filteredCompanies.length) {
      return filteredCompanies;
    }

    const filteredByEndpoint = companies.filter(company => {
      const hasEndpoint = company[type + '_settings'].find((endpoint: { [x: string]: string; id: any; }) => {
        return (~endpoint[endpointFilterParam].toLowerCase().indexOf(name)) || (~endpoint.id.toString().indexOf(name));
      });

      if (hasEndpoint) {
        return true;
      }

    });

    if (!filteredByEndpoint.length) {
      if (filteredCompanies.length) {
        return filteredCompanies;
      }
      return [];
    }

    const final = filteredByEndpoint.map((company) => {
      const filteredEndpoints = company[type + '_settings'].filter((endpoint: { [x: string]: string; id: any; }) => {
        return (~endpoint[endpointFilterParam].toLowerCase().indexOf(name)) || (~endpoint.id.toString().indexOf(name));
      });
      const newComp = { ...company };
      newComp[type + '_settings'] = filteredEndpoints;
      return newComp;
    });
    return final;
  }
}
