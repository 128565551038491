import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '../../services';

@Injectable({
  providedIn: 'root'
})
export class TrafficAnalyzerGuard  {
  constructor(public auth: AuthService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.isAuthenticated() || !this.auth.partner.is_traffic_analyzer) {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
