/* eslint-disable max-len */
import { TableConfig, AdminsInterface } from '../../interfaces';

export const adminTableConfig: TableConfig.IDataTableConfig = {
  columns: [
    {
      label: 'ID',
      rowProperty: 'id',
      sortable: false,
      width: '150px'
    },
    {
      label: 'Name',
      rowProperty: 'admin_name',
      sortable: false,
      width: '150px'
    },
    {
      label: 'QPS',
      rowProperty: 'dspQps',
      sortable: false,
      width: '150px'
    },
    {
      label: 'Incoming QPS',
      rowProperty: 'incomingQPS',
      sortable: false,
      width: '150px'
    },
    {
      label: 'Outgoing QPS',
      rowProperty: 'outgoingQPS',
      sortable: false,
      width: '150px'
    },
    {
      label: 'Use SSL',
      rowProperty: 'useSsl',
      sortable: false,
      width: '150px'
    },
    {
      label: 'Traffic Analyzer',
      rowProperty: 'is_traffic_analyzer',
      sortable: false,
      width: '150px'
    },
    {
      label: 'Not Payment',
      rowProperty: 'not_paid',
      sortable: false,
      width: '150px'
    },
    {
      label: 'Alerts',
      rowProperty: 'is_slack_alerts',
      sortable: false,
      width: '150px'
    },
    {
      label: 'UI Deactivation',
      rowProperty: 'allPartnerUsersIsDisabled',
      sortable: false,
      width: '150px'
    },
    {
      label: 'Automatic access limitation',
      rowProperty: 'has_automatic_access_limitation',
      sortable: false,
      width: '200px'
    },
    {
      label: 'WL Prebid adaptor',
      rowProperty: 'wl_prebid',
      sortable: false,
      width: '200px'
    },
    {
      label: 'Prebid.JS adaptor',
      rowProperty: 'js_prebid',
      sortable: false,
      width: '200px'
    },
  ],
  rowActions: [
    {
      rowName: 'id',
      hasFunc: false,
      action: [],
      hasControl: false,
      width: '60%',
    },
    {
      rowName: 'financial_name',
      hasFunc: true,
      hasControl: false,
      width: '60%',
      rowFn: (td: AdminsInterface.IAdminPartnerData) => (td.financial_name ?? td.financial_name === null) ? td.financial_name : td.name,
      getClass: (td: AdminsInterface.IAdminPartnerData) => td.allPartnerUsersIsDisabled ? 'red' : 'green',
      action: [],
    },
    { rowName: 'dspQps', hasFunc: false, action: [], hasControl: false, width: '60%', },
    {
      rowName: 'incomingQPS',
      hasFunc: true,
      hasControl: false,
      width: '60%',
      rowFn: (td: AdminsInterface.IAdminPartnerData) => `${td.incomingQPS} / ${td.outgoingStandart}`,
      getClass: (td: AdminsInterface.IAdminPartnerData) => '',
      action: [],
    },
    {
      rowName: 'outgoingQPS',
      hasFunc: false,
      hasControl: false,
      width: '60%',
      rowFn: (td: AdminsInterface.IAdminPartnerData) => `${td.outgoingQPS} / ${td.incomingStandart}`,
      getClass: (td: AdminsInterface.IAdminPartnerData) => '',
      action: [],
    },
    {
      rowName: 'useSsl',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      getClass: (td: AdminsInterface.IAdminPartnerData) => '',
      action: [
        { actionType: 'updateSsl', label: '', element: 'checkbox', iconName: '' },
      ]
    },
    {
      rowName: 'is_traffic_analyzer',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      getClass: (td: AdminsInterface.IAdminPartnerData) => '',
      action: [
        { actionType: 'updateTrafficAnalyzer', label: '', element: 'checkbox', iconName: '' },
      ]
    },
    {
      rowName: 'not_paid',
      hasFunc: true,
      hasControl: true,
      width: '60%',
      rowFn: (td: AdminsInterface.IAdminPartnerData) => Boolean(td.has_automatic_access_limitation),
      getClass: (td: AdminsInterface.IAdminPartnerData) => '',
      action: [
        { actionType: 'updateNotPayment', label: '', element: 'checkbox', iconName: '' },
      ]
    },
    {
      rowName: 'is_slack_alerts',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      getClass: (td: AdminsInterface.IAdminPartnerData) => '',
      action: [
        { actionType: 'updateSlackAlerts', label: '', element: 'checkbox', iconName: '' },
      ]
    },
    {
      rowName: 'allPartnerUsersIsDisabled',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      getClass: (td: AdminsInterface.IAdminPartnerData) => '',
      action: [
        { actionType: 'changeDeactivationStatus', label: '', element: 'checkbox', iconName: '' },
      ]
    },
    {
      rowName: 'has_automatic_access_limitation',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      getClass: (td: AdminsInterface.IAdminPartnerData) => '',
      action: [
        { actionType: 'changeAccessLimitation', label: '', element: 'checkbox', iconName: '' },
      ]
    },
    {
      rowName: 'wl_prebid',
      hasFunc: true,
      hasControl: true,
      width: '60%',
      rowFn: (td: AdminsInterface.IAdminPartnerData) => Boolean(td.wl_prebid),
      getClass: (td: AdminsInterface.IAdminPartnerData) => '',
      action: [
        { actionType: 'changeWLPrebid', label: '', element: 'checkbox', iconName: '' },
      ]
    },
    {
      rowName: 'js_prebid',
      hasFunc: true,
      hasControl: true,
      width: '60%',
      rowFn: (td: AdminsInterface.IAdminPartnerData) => Boolean(td.js_prebid),
      getClass: (td: AdminsInterface.IAdminPartnerData) => '',
      action: [
        { actionType: 'changeJSPrebid', label: '', element: 'checkbox', iconName: '' },
      ]
    },
  ],
  type: 'adminTable',
};
