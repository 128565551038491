import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-multi-antd',
  templateUrl: './select-multi-antd.component.html',
  styleUrls: ['./select-multi-antd.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMultiAntdComponent implements OnInit {
  item: any[];
  @Input() items: any[];
  @Input() selectedItems: any[];
  @Input() placeholder: string;
  @Input() allowedFilter: number;
  @Input() blockerFilter: number;
  @Input() type: string;
  @Input() attributeType: string;
  @Input() control: AbstractControl = new FormControl();
  @Output() changeItems = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (this.selectedItems !== undefined) {
      this.item = this.selectedItems;
    } else {
      this.item = [-1];
    }
    if (this.type === 'reports' || this.type === 'reports-table') {
      const items = {
        item: this.item,
        type: this.attributeType
      };
      this.changeItems.emit(items);
    } else {
      this.changeItems.emit(this.item);
    }
  }

  onChangeCategory(event: number[]): void {
    const arrayWithAll = this.items.filter(element => element.id !== -1);
    if (event.includes(-1) && event.indexOf(-1) === 0 && event.length > 1) {
      this.item = event = event.filter(element => element !== -1);
    } else if (event.includes(-1) && event.indexOf(-1) !== 0) {
      this.item = event = event.filter(element => element === -1);
    }
    if ((event.length === arrayWithAll.length) && arrayWithAll.length > 1) {
      this.item = event = [-1];
    }
    if (event.length === 0) {
      this.item = [-1];
    }
    if (this.type === 'reports' || this.type === 'reports-table') {
      const items = {
        item: this.item,
        type: this.attributeType
      };
      this.changeItems.emit(items);
    } else {
      this.changeItems.emit(this.item);
    }
  }
}