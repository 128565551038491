import { Component, Input, Output, EventEmitter } from '@angular/core';

import { AuthService } from '@core/services';
import { DropDownItem } from '@core/interfaces';

@Component({
  selector: 'app-qps-head-item',
  templateUrl: './qps-head-item.component.html',
  styleUrls: ['./qps-head-item.component.scss']
})
export class QpsHeadItemComponent {

  @Input() items: {
    attr: string,
    label: string,
    active: boolean,
    disabled?: boolean,
    id: number
  }[] = [{ attr: 'Jopa', label: '123', active: false, id: 0 }];
  @Input() iconClass: string;
  @Input() multi: string;
  @Input() opened = false;
  @Output() openedChange = new EventEmitter<boolean>();
  @Output() optionChanged = new EventEmitter<{ attr: string, label: string, active: boolean, id: number }[]>();
  @Output() selectedEndpoint = new EventEmitter<{ id: number, active: boolean }>();
  @Input() title: string;
  @Input() partnerQps: { real: number, standart: number };
  @Input() side: string;

  constructor(
    private authService: AuthService,
  ) { }

  onDropdownBodyClicked(e: MouseEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  toggleOpened(e: MouseEvent): void {
    e.preventDefault();
    this.opened = !this.opened;
    this.openedChange.emit(this.opened);
  }

  toggleSelection(e: MouseEvent, attr: string): boolean {
    e.preventDefault();
    e.stopImmediatePropagation();
    //do stuff
    if (this.items.find(i => i.attr === attr).disabled) {
      return;
    }
    if (!this.multi) {
      this.onItemSelected(attr);
      this.opened = false;
      this.optionChanged.emit(this.items);
      this.openedChange.emit(this.opened);
      return false;
    }

    this.onMultiItemSelected(attr);
    this.optionChanged.emit(this.items);
  }

  sendIdEndpoint(e: MouseEvent, attr: string): void {
    if (this.side == 'dsp' && ['viewer', 'external'].indexOf(this.authService.userRole) === -1) {
      this.selectedEndpoint.emit({ id: this.items.find(i => i.attr === attr).id, active: true });
    }
  }

  private onItemSelected(attr: string): void {
    this.items.forEach((i: DropDownItem) => {
      if (i.attr !== attr) {
        i.active = false;
      } else {
        i.active = true;
      }
    });
  }

  private onMultiItemSelected(attr: string): void {
    this.items.forEach((i: DropDownItem) => {
      if (i.attr == attr) {
        i.active = !i.active;
      }
    });
  }

}
