import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { ErrorsHandlingService, AppOptionsService } from '@core/services';

import { NgxSpinnerService } from 'ngx-spinner';
import { ApexAxisChartSeries } from 'ng-apexcharts';

import { filterNormalize, formatDate, generateCardColor } from '@core/helpers';

import { DashboardsInterface } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DashboardDynamicRatesService {

  public dynamicRatesChartSeries: ApexAxisChartSeries = [];
  public dynamicRatesLimitedSeries: ApexAxisChartSeries = [];
  public dynamicRatesChartOptions = null;
  public dynamicRatesTotalValue: { [key: string]: number } = {};
  public dynamicRatesMaxIAxisValue: number;
  public totalSspSpendChartSeries = [];
  public totalSspSpendLimitedSeries = [];
  public totalSspSpendChartOptions = null;
  public totalDspSpendChartSeries = [];
  public totalDspSpendLimitedSeries = [];
  public totalDspSpendChartOptions = null;
  public totalImpChartSeries = [];
  public totalImpLimitedSeries = [];
  public totalImpChartOptions = null;
  public totalProfitChartSeries = [];
  public totalProfitLimitedSeries = [];
  public totalProfitChartOptions = null;

  public get isShowChart(): boolean {
    return this._isShowChart;
  }

  public set isShowChart(value: boolean) {
    this._isShowChart = value;
  }

  public get isShowTotalCharts(): boolean {
    return this._isShowTotalCharts;
  }

  public set isShowTotalCharts(value: boolean) {
    this._isShowTotalCharts = value;
  }

  private apiUrl = '';

  private _isShowChart = false;
  private _isShowTotalCharts = false;

  constructor(
    private http: HttpClient,
    private loadingService: NgxSpinnerService,
    private options: AppOptionsService,
    private errorsHandlingService: ErrorsHandlingService,
  ) {
    this.apiUrl = this.options.getApiUrl();
  }

  public onToggleDynamicRatesCardHandler(data: DashboardsInterface.ICard[]): void {
    const enabledCards: string[] = data
      .filter((card: DashboardsInterface.ICard) => card.enabled)
      .map((card: DashboardsInterface.ICard) => card.name);

    this.dynamicRatesLimitedSeries = this.dynamicRatesChartSeries.filter(series => enabledCards.includes(series.name));
  }

  public getDynamicChartDataHandler(dynamicFormGroup: FormGroup): void {
    const { region, ssp, dsp, metric, ad_format, traffic_type, os } = dynamicFormGroup.value;
    const filter = { region, ssp, dsp, metric, ad_format, traffic_type, os };

    this.loadingService.show();
    this.getDynamicChart(filterNormalize(filter))
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(res => {
        const data = JSON.parse(JSON.stringify(res.data));
        this.isShowChart = !data.every(item => item.today == 0 && item.yesterday === 0 && item.two_days_ago === 0);
        const chart = this.transformToDynamicChartData(res);
        this.dynamicRatesChartSeries = chart.series;
        this.dynamicRatesLimitedSeries = JSON.parse(JSON.stringify(chart.series));
        chart.chartOptions.yaxis.max = this.doubleValue(res.maxY);
        chart.chartOptions.annotations = null;
        this.dynamicRatesChartOptions = chart.chartOptions;
        this.dynamicRatesTotalValue = this.calculateAllValuesForDynamicChart(res.total)[metric];


        const sspSpendChart = this.transformDataForTotalCharts(res.total.ssp_price);
        this.totalSspSpendChartSeries = sspSpendChart.series;
        this.totalSspSpendLimitedSeries = JSON.parse(JSON.stringify(sspSpendChart.series));
        this.totalSspSpendChartOptions = sspSpendChart.chartOptions;

        const dspSpendChart = this.transformDataForTotalCharts(res.total.dsp_price);
        this.totalDspSpendChartSeries = dspSpendChart.series;
        this.totalDspSpendLimitedSeries = JSON.parse(JSON.stringify(dspSpendChart.series));
        this.totalDspSpendChartOptions = dspSpendChart.chartOptions;

        const impChart = this.transformDataForTotalImpCharts(res.total.imp);
        this.totalImpChartSeries = impChart.series;
        this.totalImpLimitedSeries = JSON.parse(JSON.stringify(impChart.series));
        this.totalImpChartOptions = impChart.chartOptions;

        const profitChart = this.transformDataForTotalCharts(res.total.platform_revenue);
        this.totalProfitChartSeries = profitChart.series;
        this.totalProfitLimitedSeries = JSON.parse(JSON.stringify(profitChart.series));
        this.totalProfitChartOptions = profitChart.chartOptions;

        if (this.areAllTotalsZero(res.total)) {
          this.isShowTotalCharts = false;
        } else {
          this.isShowTotalCharts = true;
        }
      }, error => this.errorsHandlingService.handleError(error));
  }

  private doubleValue(inputValue: number) {
    return inputValue * 1.1;
  }

  private calculateValuesByDayForDynamicChart(data) {
    const valuesByDay = {};
    data.forEach(item => {
      let key = item.x.toLowerCase();
      if (key === '2d ago') {
        key = 'two_days_ago';
      }
      valuesByDay[key] = item.y;
    });
    return valuesByDay;
  }

  private calculateAllValuesForDynamicChart(obj) {
    return {
      dsp_price: this.calculateValuesByDayForDynamicChart(obj.dsp_price.data),
      imp: this.calculateValuesByDayForDynamicChart(obj.imp.data),
      platform_revenue: this.calculateValuesByDayForDynamicChart(obj.platform_revenue.data),
      ssp_price: this.calculateValuesByDayForDynamicChart(obj.ssp_price.data),
    };
  }

  private transformToDynamicChartData(chart: DashboardsInterface.IDataResForTotal): DashboardsInterface.IChartDynamicTransformData {
    const dynamicFields = Object.keys(chart.config).filter(
      (key) => key !== 'date',
    );

    chart.data = chart.data.map(item => {
      Object.keys(item).forEach(key => {
        if (item[key] === 0) {
          delete item[key];
        }
      });
      return item;
    });

    const transformedData = chart.data.map((item) => ({
      x: formatDate(new Date(item.date)),
      y: dynamicFields.map((field) => Number(item[field])),
    }));

    const series = dynamicFields.map((field, index) => {
      const name = chart.config[field].name;
      const color = generateCardColor(field);

      let chartData = transformedData.map((item) => ({
        x: item.x,
        y: item.y[index],
      }));

      chartData = chartData.map(item => {
        if (Number.isNaN(item.y)) {
          delete item.y;
        }

        return item;
      });

      return {
        field,
        name,
        color,
        data: chartData,
      };
    });

    const colors = series.map((series) => ({
      name: series.name,
      color: series.color,
    }));

    const limitedSeries = series.slice(0, 3);

    const chartOptions: Partial<DashboardsInterface.ChartOptions> = {
      chart: {
        type: 'line',
        height: 800,
        zoom: {
          enabled: false,
        }
      },
      plotOptions: {},
      xaxis: {
        type: 'numeric',
        labels: {
          formatter: function (value) {
            const formatter = new Intl.DateTimeFormat('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });
            const formattedDate = formatter.format(new Date(value));
            return formattedDate;
          },
        },
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true
      },
      yaxis: {
        opposite: false,
        min: 0,
        max: 160000,
        tickAmount: 8,
        labels: {
          formatter: function (val) {
            if (val) {
              return val.toFixed(0); // Removes decimal places from the y-axis labels
            } else {
              return [];
            }
          }
        }
      },
      colors: [],
    };

    const total = chart.total;

    return {
      series,
      colors,
      limitedSeries,
      chartOptions,
      total,
    };
  }

  private transformDataForTotalCharts(chart: DashboardsInterface.TotalSubChartsRes): DashboardsInterface.IChartTransformDataForTotal {
    const dynamicFields = Object.keys(chart.config).filter(
      (key) => key !== 'date',
    );

    const seriesData = dynamicFields.map((field, index) => {
      return {
        x: chart.config[field].name,
        y: chart.data[index].y,
      };
    });

    const series = [
      {
        data: seriesData,
        color: '#0070D2',
      }
    ];

    const limitedSeries = series.slice(0, 1);

    const chartOptions: Partial<DashboardsInterface.ChartOptions> = {
      chart: {
        type: 'bar',
        height: 100,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      dataLabels: {
        enabled: true,
        // textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        formatter: function (val, opt) {
          if (typeof val === 'number') {
            if (val >= 1000) {
              return '$' + (val / 1000).toFixed(2) + 'K';
            } else {
              return '$' + val.toFixed(2);
            }
          }
        },
        // offsetX: 0,
        dropShadow: {
          enabled: true
        }
      },
      xaxis: {
        labels: {
          show: false,
        }
      },
      yaxis: {
        labels: {
          show: true,
        }
      },
      tooltip: {
        enabled: false
      },
      stroke: {
        width: 0
      },
      legend: {
        show: false
      },
      colors: ['#0070D2', '#0070D2', '#0070D2'],
    };

    return {
      series,
      limitedSeries,
      chartOptions,
    };
  }

  private transformDataForTotalImpCharts(chart: DashboardsInterface.TotalSubChartsRes): DashboardsInterface.IChartTransformDataForTotal {
    const dynamicFields = Object.keys(chart.config).filter(
      (key) => key !== 'date',
    );

    const seriesData = dynamicFields.map((field, index) => {
      return {
        x: chart.config[field].name,
        y: chart.data[index].y,
      };
    });

    const series = [
      {
        data: seriesData,
        color: '#0070D2',
      }
    ];


    const limitedSeries = series.slice(0, 1);

    const chartOptions: Partial<DashboardsInterface.ChartOptions> = {
      chart: {
        type: 'bar',
        height: 100,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        formatter: function (val, opt) {
          if (typeof val === 'number') {
            if (val >= 1000) {
              return ' ' + (val / 1000).toFixed(2) + 'K';
            } else {
              return ' ' + val.toFixed(2);
            }
          }
        },
        offsetX: 20,
        dropShadow: {
          enabled: true
        }
      },
      xaxis: {
        labels: {
          show: false,
        }
      },
      yaxis: {
        labels: {
          show: true,
        }
      },
      tooltip: {
        enabled: false
      },
      stroke: {
        width: 0
      },
      legend: {
        show: false
      },
      colors: ['#0070D2', '#0070D2', '#0070D2'],
    };

    return {
      series,
      limitedSeries,
      chartOptions,
    };
  }

  // Helper function to check if all items in an array have y equal to 0
  private allItemsZero(data: { y: number }[]): boolean {
    return data.every(item => item.y === 0);
  }

  // Function to check all required properties in res.total
  private areAllTotalsZero(total: any): boolean {
    return (
      this.allItemsZero(total.ssp_price.data) &&
      this.allItemsZero(total.dsp_price.data) &&
      this.allItemsZero(total.imp.data) &&
      this.allItemsZero(total.platform_revenue.data)
    );
  }

  private getDynamicChart(filter: DashboardsInterface.IDynamicChartReqData): Observable<DashboardsInterface.IDataResForTotal> {
    return this.http.post<DashboardsInterface.IDataResForTotal>(`${this.apiUrl}/analytics-dashboards/rates`, { filter: filter })
      .pipe(catchError(this.errorsHandlingService.processError));
  }
}
