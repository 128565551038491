import { TableConfig, TableDataResponse } from '../../interfaces';

export const samplesLoggerTableConfig: TableConfig.IDataTableConfig = {
  columns: [
    { label: 'Log Time', rowProperty: 'log_time', sortable: true, width: '180px' },
    { label: 'SSP', rowProperty: 'ssp_name', sortable: true, width: '250px' },
    { label: 'DSP', rowProperty: 'dsp_name', sortable: true, width: '250px' },
    { label: 'Log Event', rowProperty: 'log_event', sortable: true, width: '300px' },
    { label: 'Incoming Request', rowProperty: 'ad_request', sortable: true, width: '180px' },
    { label: 'SSP Bid Request', rowProperty: 'ssp_request', sortable: true, width: '180px' },
    { label: 'DSP Bid Request', rowProperty: 'dsp_request', sortable: true, width: '180px' },
    { label: 'DSP Bid Response', rowProperty: 'dsp_response', sortable: true, width: '180px' },
    { label: 'SSP Bid Response', rowProperty: 'ssp_response', sortable: true, width: '180px' },
  ],
  rowActions: [
    { rowName: 'log_time', hasFunc: false, action: [], hasControl: false, width: '60%', },
    { rowName: 'ssp_name', hasFunc: false, action: [], hasControl: false, width: '60%', },
    { rowName: 'dsp_name', hasFunc: false, action: [], hasControl: false, width: '60%', },
    { rowName: 'log_event', hasFunc: false, action: [], hasControl: false, width: '60%', },
    {
      rowName: 'ad_request',
      hasFunc: true,
      hasControl: true,
      width: '60%',
      rowFn: (item: TableDataResponse) => item.ad_request ? 'Incoming_request.txt' : ' - ',
      getClass: (item: TableDataResponse) => item.ad_request ? 'td-sample' : 'td-nosample',
      action: [
        { actionType: 'downloadSample', label: '', element: 'buttonRow', iconName: '' },
      ],
    },
    {
      rowName: 'ssp_request',
      hasFunc: true,
      hasControl: true,
      width: '60%',
      rowFn: (item: TableDataResponse) => item.ssp_request ? 'Bid_request_sample.txt' : ' - ',
      getClass: (item: TableDataResponse) => item.ssp_request ? 'td-sample' : 'td-nosample',
      action: [
        { actionType: 'downloadSample', label: '', element: 'buttonRow', iconName: '' },
      ],
    },
    {
      rowName: 'dsp_request',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      rowFn: (item: TableDataResponse) => item.dsp_request ? 'Bid_request_sample.txt' : ' - ',
      getClass: (item: TableDataResponse) => item.dsp_request ? 'td-sample' : 'td-nosample',
      action: [
        { actionType: 'downloadSample', label: '', element: 'buttonRow', iconName: '' },
      ],
    },
    {
      rowName: 'dsp_response',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      rowFn: (item: TableDataResponse) => item.dsp_response ? 'Bid_response_sample.txt' : ' - ',
      getClass: (item: TableDataResponse) => item.dsp_response ? 'td-sample' : 'td-nosample',
      action: [
        { actionType: 'downloadSample', label: '', element: 'buttonRow', iconName: '' },
      ],
    },
    {
      rowName: 'ssp_response',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      rowFn: (item: TableDataResponse) => item.ssp_response ? 'Bid_response_sample.txt' : ' - ',
      getClass: (item: TableDataResponse) => item.ssp_response ? 'td-sample' : 'td-nosample',
      action: [
        { actionType: 'downloadSample', label: '', element: 'buttonRow', iconName: '' },
      ],
    },
  ],
  type: 'samplesLogger',
};