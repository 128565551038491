import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-multi-antd-control',
  templateUrl: './select-multi-antd-control.component.html',
  styleUrls: ['./select-multi-antd-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMultiAntdControlComponent implements OnInit {
  @Input() set control(value: FormControl) {
    this._control = value;
  }

  public get control(): FormControl {
    return this._control;
  }

  @Input() public set items(value: any[]) {
    this._items = value;
  }

  public get items() {
    return this._items;
  }

  @Input() public set selectedItems(value: any[]) {
    this._selectedItems = value;
  }

  public get selectedItems() {
    return this._selectedItems;
  }

  @Input() public set placeholder(value: string) {
    this._placeholder = value;
  }

  public get placeholder(): string {
    return this._placeholder;
  }

  @Input() public set allowedFilter(value: number) {
    this._allowedFilter = value;
  }

  public get allowedFilter(): number {
    return this._allowedFilter;
  }

  @Input() public set blockerFilter(value: number) {
    this._blockerFilter = value;
  }

  public get blockerFilter(): number {
    return this._blockerFilter;
  }

  @Input() public set type(value: string) {
    this._type = value;
  }

  @Input() public set attributeType(value: string) {
    this._attributeType = value;
  }

  @Output() changeItems = new EventEmitter();

  public get item() {
    return this.control.value;
  }

  public get type(): string {
    return this._type;
  }

  public get attributeType(): string {
    return this._attributeType;
  }

  private _items: any[];
  private _selectedItems: any[];
  private _placeholder: string;
  private _allowedFilter: number;
  private _blockerFilter: number;
  private _type: string;
  private _attributeType: string;
  private _control = new FormControl();

  constructor(
    private cd: ChangeDetectorRef,
  ) { }


  ngOnInit(): void {
    if (this.selectedItems !== undefined) {
      this.control.patchValue(this.selectedItems);
    } else {
      this.control.patchValue([-1]);
    }
    if (this.type === 'reports' || this.type === 'reports-table') {
      const items = {
        item: this.item,
        type: this.attributeType
      };
      this.changeItems.emit(items);
    } else {
      this.changeItems.emit(this.item);
    }
  }

  onChangeCategory(event: number[]): void {
    const arrayWithAll = this.items.filter(element => element.id !== -1);
    if (event.includes(-1) && event.indexOf(-1) === 0 && event.length > 1) {
      event = event.filter(element => element !== -1);
      this.control.patchValue(event);
    } else if (event.includes(-1) && event.indexOf(-1) !== 0) {
      event = event.filter(element => element === -1);
      this.control.patchValue(event);
    }
    if ((event.length === arrayWithAll.length) && arrayWithAll.length > 1) {
      event = [-1];
      this.control.patchValue(event);
    }
    if (event.length === 0) {
      this.control.patchValue([-1]);
    }
    if (this.type === 'reports' || this.type === 'reports-table') {
      const items = {
        item: this.item,
        type: this.attributeType
      };
      this.changeItems.emit(items);
    } else {
      this.changeItems.emit(this.item);
    }
  }
}
