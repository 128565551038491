import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { UserRole } from '../../enum';
import { AuthService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(public auth: AuthService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.isAuthenticated() || this.auth.userRole === UserRole.guest) {
      this.router.navigate(['login']);
      return false;
    }
    if (this.auth.userRole === UserRole.external) {
      if (state.url.indexOf('statistics/reports') === -1) {
        this.router.navigateByUrl('statistics/reports');
        return false;
      }
    }
    return true;
  }
}
