import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IScanState } from '../../interfaces';
import { DataManagerService } from '../data-manager';
import { NotificationService } from '../notification';
import { NotificationComponent } from '@shared/components';

@Injectable({
  providedIn: 'root',
})
export class ScanProgressService {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private timer = timer(0, 1000);
  private clock: Subscription = new Subscription;
  private response!: Promise<any>;
  private timerState: IScanState = {
    isScanning: false,
    scanningMessage: 'Logging time:',
    timerTime: '01:00',
    scanningFinished: false,
    responseTrafficLoggerData: [],
    responseUuid: null,
    multiplier: 0,
  };
  scanState$ = new BehaviorSubject<IScanState>(this.timerState);
  private time!: string | number;

  constructor(
    private dataManager: DataManagerService,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
  ) {
  }

  startScan(data: { time: string | number; }, callback: (arg0: null) => void) {
    this.time = data.time;
    this.abortConnection();
    this.response = this.dataManager.verifyTraffic(data, takeUntil(this.ngUnsubscribe));
    this.response.then(result => {
      this.timerStop();
      this.timerState.isScanning = false;
      this.timerState.scanningMessage = 'Logging is finished';
      this.timerState.scanningFinished = true;
      this.timerState.responseTrafficLoggerData = result.data;
      this.timerState.responseUuid = result.uuid;
      this.timerState.multiplier = result.multiplier;
      callback(result);
    },
      reject => {
        let errDetails = ['Error occurs! Service is busy.  Please, try later!'];
        if (!!reject.error && !!reject.error.errors) {
          errDetails = Object.values(reject.error.errors);
        }

        this.timerStop();
        this.timerState.isScanning = false;
        this.timerState.scanningFinished = false;
        this.timerState.responseTrafficLoggerData = [];
        this.timerState.isScanning = false;
        this.timerState.scanningMessage = 'Logging finished with error';
        callback(null);
        this.showNotification('error', 'error', 'error', errDetails.join('\r\n'));
      },
    );


    this.timerState.isScanning = true;
    this.timerState.scanningFinished = false;
    this.timerState.scanningMessage = 'Logging time:';
    this.timerState.responseTrafficLoggerData = [];

    this.clock = this.timer.subscribe(vl => {
      if (vl > 0) {
        const seconds = ((this.time as any) * 60) - vl;
        const minutes = Math.floor((seconds / 60));
        const displayMinutes = ((this.time as any) * 60) - ((minutes * 60) + vl);
        this.timerState.timerTime = '0' + minutes + ':' + (displayMinutes < 10 ? '0' + displayMinutes : displayMinutes);
        this.timerState.isScanning = true;
        this.updateState();
        this.checkTimer(vl, callback);
      }
    });
  }

  changeTimerValue(value: string | number) {
    if ((value as number) > 0 && (value as number) < 10) {
      this.timerState.timerTime = '0' + value + ':00';
    } else if (value == 10) {
      this.timerState.timerTime = value + ':00';
    } else {
      this.timerState.timerTime = '00:00';
    }
    this.updateState();
  }

  abortConnection() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.clock) {
      this.clock.unsubscribe();
    }
    this.timerState.isScanning = false;
    this.timerState.scanningFinished = false;
    this.timerState.scanningMessage = 'Logging time:';
    this.timerState.timerTime = '0' + this.time + ':00';
    this.updateState();
  }

  private checkTimer(val: number, callback: (arg0: null) => void) {
    if (val >= ((this.time as any) * 60)) {
      this.timerStop();
    }
  }
  private timerStop() {
    this.clock.unsubscribe();
    this.timerState.timerTime = '';
    this.timerState.scanningMessage = 'Processing results...';
    this.updateState();
  }
  private updateState() {
    this.scanState$.next(this.timerState);
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data });
  }
}
