<ng-template #template let-data="data">
  <div class="notification" id="notificationTopRight">
    <div class="notification__content content-block">
      <span class="content-block__icon">
        <i nz-icon [nzType]="iconTypes[data.iconType]" nzTheme="outline"
          [style.color]="notificationColors[data.color]"></i>
      </span>
      <p *ngIf="data.notificationType === 'error'" class="content-block__description">{{data.description}}</p>
      <p *ngIf="data.notificationType === 'success'" class="content-block__description description-success">
        <span class="description-success__left">{{data.leftPartOfText}}</span>
        <span class="description-success__center" [style.font-weight]="data.fontWeight">{{data.description}}</span>
        <span class="description-success__right">{{data.rightPartOfText}}</span>
      </p>
      <p class="content-block__description description-info" *ngIf="data.notificationType === 'info'">
        <span class="description-info__left">{{data.leftPartOfText}}</span>
        <span class="description-info__center" [style.color]="data.textColor ?? '#000'"
          [style.font-weight]="data.fontWeight ?? 'normal'" (click)="goToKnowledgeBase()">{{data.description}}</span>
        <span class="description-info__right">{{data.rightPartOfText}}</span>
      </p>
      <p class="content-block__description description-adapter" *ngIf="data.notificationType === 'adapter-error'">
        <span class="description-adapter__text">
          {{data.leftPartOfText}}
          <span class="description-adapter__center" [style.color]="data.textColor ?? '#000'"
            [style.font-weight]="data.fontWeight ?? 'normal'" (click)="goToSettings()">{{data.description}}</span>
          <span>{{data.rightPartOfText}}</span>
        </span>
      </p>
    </div>
  </div>
</ng-template>