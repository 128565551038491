import { TableConfig, FilterListInterface } from '../../interfaces';

export const filterListTableConfig: TableConfig.IDataTableConfig = {
  columns: [
    { label: 'Name', rowProperty: 'name', sortable: true, width: '100px' },
    { label: 'Type', rowProperty: 'type', sortable: true, width: '100px' },
    { label: 'Category', rowProperty: 'category', sortable: true, width: '100px' },
    { label: 'Created', rowProperty: 'create_at', sortable: true, width: '100px' },
    { label: 'Updated', rowProperty: 'updated_at', sortable: true, width: '100px' },
    { label: 'Control', rowProperty: 'control', sortable: false, width: '100px' },
  ],
  rowActions: [
    {
      rowName: 'name',
      hasFunc: true,
      rowFn: (item: FilterListInterface.FilterList) => `${item.name}: ${item.values_count} item(s)`,
      action: [],
      hasControl: false,
      width: '60%',
      getClass: (item: FilterListInterface.FilterList) => '',
    },
    { rowName: 'record_type', hasFunc: false, action: [], hasControl: false, width: '60%', },
    { rowName: 'type', hasFunc: false, action: [], hasControl: false, width: '60%', },
    { rowName: 'create_at', hasFunc: false, action: [], hasControl: false, width: '60%', },
    { rowName: 'updated_at', hasFunc: false, action: [], hasControl: false, width: '60%', },
    {
      rowName: 'control',
      hasFunc: false,
      hasControl: true,
      width: '60%',
      action: [
        { actionType: 'editList', label: 'Edit', element: 'button', iconName: 'edit' },
        { actionType: 'deleteList', label: 'Delete', element: 'button', iconName: 'delete' }
      ]
    },
  ],
  type: 'filterList',
};
