/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Observable, of, throwError as _throw } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';

import { FilterListInterface, TableConfig } from '../../interfaces';
import { AppOptionsService } from '../app-options';
import { HttpUtils } from '../../class';
import { AuthService } from '../auth';
import { NotificationService } from '../notification';
import { NotificationComponent } from '@shared/components';
import { FormControl } from '@angular/forms';

@Injectable(
  { providedIn: 'root' }
)
export class FilterListService {
  sortOrder = 'desc';
  sortBy = 'name';
  private apiURL;

  params = {
    pageSize: 25,
    pageIndex: 1,
  };

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private router: Router,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
    private modalService: NzModalService,
    private authService: AuthService,
  ) {
    this.apiURL = this.options.getApiUrl();
  }

  getTypes(): Observable<{ data: FilterListInterface.FilterTypes[] }> {
    return of({
      data: [
        {
          id: 'all',
          name: 'All types',
        },
        {
          id: 'white',
          name: 'Allowed list',
        },
        {
          id: 'black',
          name: 'Blocked list',
        },
      ],
    });
  }

  getCategories(): Observable<{ data: FilterListInterface.FilterCategory[] }> {
    return of({
      data: [
        {
          id: 'all',
          name: 'All Categories'
        },
        {
          id: 'bundle_domain',
          name: 'Domains/Bundles',
        },
        {
          id: 'bundle',
          name: 'Bundles'
        },
        {
          id: 'domain',
          name: 'Domains'
        },
        {
          id: 'publisher_id',
          name: 'Publisher IDs',
        },
        {
          id: 'crid',
          name: 'CRIDs',
        },
        {
          id: 'adm_pattern',
          name: 'Domains (ADM)',
        },
        {
          id: 'adomain',
          name: 'Adomains'
        },
        {
          id: 'ip',
          name: 'IP Address'
        },
        {
          id: 'top_lvl_domain',
          name: 'Top-Level Domains',
        },
        {
          id: 'keyword_crid',
          name: 'Keyword CRIDs',
        }
      ],
    });
  }

  exportValuesFromCreatedList(id: number, hash: string): Observable<any> {
    const requestParams = {
      id,
      hash,
    };
    if (!hash.length) {
      delete requestParams.hash;
    }
    return this.http.post(`${this.apiURL}/list-manager/export`, requestParams)
      .pipe(catchError(this.processError));
  }

  exportValuesFromNewList(hash: string): Observable<any> {
    return this.http.post(`${this.apiURL}/list-manager/export`, { hash })
      .pipe(catchError(this.processError));
  }

  getAllLists(): Observable<{ data: FilterListInterface.FilterList[] }> {
    return of({
      data: []
    });
  }

  getEndpoints(): Observable<FilterListInterface.EndpointsData> {

    const params = new HttpParams().append('without_deleted', '1');

    return this.http.get<FilterListInterface.EndpointsData>(`${this.apiURL}/endpoints`, { params: params })
      .pipe(map(res => {
        res.dsp = res?.dsp.map(item => {
          return { checked: false, disabled: false, tooltip: item.name, label: item.name, value: item.id, ...item };
        });

        res.ssp = res?.ssp.map(item => {
          return { checked: false, disabled: false, tooltip: item.name, label: item.name, value: item.id, ...item };
        });

        return res;
      }),
        catchError(this.processError));
  }

  getEndpointsNew(params: any): Observable<FilterListInterface.PaginationState<FilterListInterface.GeneralEndpoints>> {
    const requestParams = HttpUtils.getRequestParams({ ...params });
    return this.http.get<FilterListInterface.PaginationState<FilterListInterface.GeneralEndpoints>>(`${this.apiURL}/endpoints/by-filter-list`,
      { params: requestParams })
      .pipe(catchError(this.processError));
  }

  getCompanies(): Observable<FilterListInterface.CompaniesDataRes> {
    const params = new HttpParams().append('without_deleted', true);

    return this.http.get<FilterListInterface.CompaniesDataRes>(`${this.apiURL}/endpoints/grouped-by-company`, { params: params })
      .pipe(map(res => {
        res.ssp = res.ssp.map(company => {
          company.endpoints = company.endpoints.map(endpoint => {
            return { checked: false, disabled: false, label: endpoint.name, value: endpoint.id, ...endpoint };
          });
          return { checked: false, label: company.name, value: company.id, indeterminate: false, ...company };
        });

        res.dsp = res.dsp.map(company => {
          company.endpoints = company.endpoints.map(endpoint => {
            return { checked: false, disabled: false, label: endpoint.name, value: endpoint.id, ...endpoint };
          });
          return { checked: false, label: company.name, value: company.id, indeterminate: false, ...company };
        });

        return res;
      }),
        catchError(this.processError));
  }


  createNewFiltersList(
    list: FilterListInterface.NewFilterList,
    endpoints: FilterListInterface.DataEndpoints): Observable<FilterListInterface.PaginationState<FilterListInterface.FilterList[]>> {
    return this.http.post<FilterListInterface.PaginationState<FilterListInterface.FilterList[]>>(`${this.apiURL}/list-manager`,
      { list, endpoints })
      .pipe(catchError(this.processError));
  }

  getAllList(params: object): Observable<FilterListInterface.PaginationState<TableConfig.ITableItem>> {
    const requestParams = HttpUtils.getRequestParams({ ...params });

    return this.http.get<FilterListInterface.PaginationState<TableConfig.ITableItem>>(`${this.apiURL}/list-manager`, { params: requestParams })
      .pipe(catchError(this.processError));
  }

  getListById(id: number): Observable<FilterListInterface.DataList> {
    return this.http.get<FilterListInterface.DataList>(`${this.apiURL}/list-manager/${id}`)
      .pipe(map(res => {
        res.data.endpoints.dsp = res.data.endpoints.dsp.map(endpoint => endpoint['id']);
        res.data.endpoints.ssp = res.data.endpoints.ssp.map(endpoint => endpoint['id']);
        return res;
      }),
        catchError(this.processError));
  }

  updateList(
    list: FilterListInterface.FilterListData,
    endpoints: FilterListInterface.DataEndpoints,
  ): Observable<FilterListInterface.DataList> {
    return this.http.post<FilterListInterface.DataList>(`${this.apiURL}/list-manager/update`,
      { list, endpoints })
      .pipe(catchError(this.processError));
  }

  getValuesForListByHash(hash: string, params: object): Observable<{ data: { values: FilterListInterface.BundlesList; hash: string } }> {
    const requestParams = {
      ...params,
      hash: hash,
    };
    return this.http.post<{ data: { values: FilterListInterface.BundlesList; hash: string } }>(`${this.apiURL}/list-manager/values`, requestParams)
      .pipe(catchError(this.processError));
  }

  getValuesForListById(id: number, hash: string, params: {
    page: number;
    rows: number;
    search: string;
  }, refresh: boolean): Observable<{ data: { values: FilterListInterface.BundlesList; hash: string } }> {
    const requestParams = {
      ...params,
      hash: hash ?? undefined,
      id: id ?? undefined,
      refresh
    };
    return this.http.post<{ data: { values: FilterListInterface.BundlesList; hash: string } }>(`${this.apiURL}/list-manager/values`, requestParams)
      .pipe(catchError(this.processError));
  }

  exportValuesByHash(hash: string): Observable<any> {
    return this.http.post(`${this.apiURL}/list-manager/export`, hash)
      .pipe(catchError(this.processError));
  }

  exportValuesById(id: number): Observable<any> {
    return this.http.post(`${this.apiURL}/list-manager/export`, id)
      .pipe(catchError(this.processError));
  }

  deleteList(id: number): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(`${this.apiURL}/list-manager/${id}`)
      .pipe(catchError(this.processError));
  }

  deleteValue(value: string | number, hash: string): Observable<{ success: boolean }> {
    const requestParams = {
      value,
      hash,
    };
    return this.http.post<{ success: boolean }>(`${this.apiURL}/list-manager/values/delete`, requestParams)
      .pipe(catchError(this.processError));
  }

  deleteValues(hash: string): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(`${this.apiURL}/list-manager/clear`, { hash })
      .pipe(catchError(this.processError));
  }

  uploadFilterList(file: File, record_type: string, hash: string): Observable<{
    status: string;
    hash: string;
    count: number;
    original_count: number;
    message: string;
  }> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('hash', hash);
    return this.http.post<{
      status: string;
      hash: string;
      count: number;
      original_count: number;
      message: string;
    }>(`${this.options.getApiUrl()}/list-manager/values/save/${record_type}`, formData)
      .pipe(catchError(this.processError));
  }

  uploadFilterListWithId(file: File, record_type: string, id: number): Observable<{
    status: string;
    hash: string;
    count: number;
    original_count: number;
  }> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('id', id.toString());
    return this.http.post<{
      status: string;
      hash: string;
      count: number;
      original_count: number;
    }>(`${this.options.getApiUrl()}/list-manager/values/save/${record_type}`, formData);
  }

  addValue(record_type: string, value: string | number, hash: string): Observable<{ hash: string; status: string; }> {
    const data = {
      hash,
      record_type,
      value,
    };
    return this.http.post<{ hash: string; status: string; }>(`${this.apiURL}/list-manager/value/save`, data)
      .pipe(catchError(this.processError));
  }

  handleError(error: { code: number, messages: string[] }): boolean {
    if (error.code === 0) {
      this.showNotificationError('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }

    if (error.code === 400) {
      this.showNotificationError('error', 'error', 'error', error.messages.join('\r\n'), '400px');
      return false;
    }

    if (error.code === 403) {
      this.showNotificationError('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }

    if (error.code === 401) {
      const errorMessage = this.authService.isExpired(this.authService.expiredDate)
        ? 'Your authentication token has been expired. Please log in again to continue using the platform'
        : error.messages.join('\r\n');
      this.showNotificationError('error', 'error', 'error', errorMessage, null);
      this.modalService.closeAll();
      void this.router.navigateByUrl('/login');
      return false;
    }

    if (error.code === 404) {
      this.showNotificationError('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }

    if (error.code === 422) {
      this.showNotificationError('error', 'error', 'error', error.messages.join('\r\n'), '400px');
      return false;
    }

    if (error.code === 500) {
      this.showNotificationError('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }

    if (error.code === 503) {
      this.showNotificationError('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }

  }

  public sourceValidationForClick(event: Event, sourceControl: FormControl, typeControl: FormControl, inputValueControl: FormControl): boolean {
    if (sourceControl.value === 'adomain') {
      if (/^(([a-z0-9-_]+\.|)+[a-z0-9-_]{3,}\.[a-z]{2,}(\.[a-z]{2,}|)(\:[0-9]+|)|([a-zA-Z0-9-_]+\.)+[a-zA-Z0-9-_]+|[0-9]+)([a-zA-Z0-9-_\/]+\?[a-zA-Z0-9=.]+|)$/i.test(inputValueControl.value)) {
        return true;
      } else {
        event.preventDefault();
        this.showNotificationError('error', 'error', 'error', `This field ${typeControl.value} is empty or incorrect`, null);
        return false;
      }
    } else if (sourceControl.value === 'ip') {
      if (this.sourceIPValidation(inputValueControl.value)) {
        return true;
      } else {
        event.preventDefault();
        this.showNotificationError('error', 'error', 'error', `This field ${typeControl.value} is empty or incorrect`, null);
        return false;
      }
    } else if (
      sourceControl.value === 'bundle_domain' ||
      sourceControl.value === 'domain' ||
      sourceControl.value === 'bundle' ||
      sourceControl.value === 'adm_pattern'
    ) {
      if (/^[A-Za-z0-9\.\-\_]+$/i.test(inputValueControl.value)) {
        return true;
      } else {
        event.preventDefault();
        this.showNotificationError('error', 'error', 'error', `This field ${typeControl.value} is empty or incorrect`, null);
        return false;
      }
    }
    else if (sourceControl.value === 'publisher_id' || sourceControl.value === 'crid') {
      return true;
    } else if (sourceControl.value === 'top_lvl_domain') {
      if (/^\*\.[a-z]{2,20}$/.test(inputValueControl.value)) {
        return true;
      } else {
        event.preventDefault();
        this.showNotificationError('error', 'error', 'error', `Invalid ${typeControl.value}. Please make sure to enter a valid Top-Level Domain in the following format: *.com`, '400px');
        return false;
      }
    } else if (sourceControl.value === 'keyword_crid') {
      if (/^.{2,20}$/.test(inputValueControl.value)) {
        return true;
      } else {
        event.preventDefault();
        this.showNotificationError('error', 'error', 'error', `This field ${typeControl.value} is empty or incorrect`, null);
        return false;
      }
    }


    return true;
  }

  private sourceIPValidation(ip: string): boolean {
    const splitted = ip.split('.');
    const nb = splitted.length;
    const first3BytesRg = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/;
    const fourthByteRg = /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (nb > 4) return false;
    if (splitted[nb - 2] == '') return false;
    if (splitted[nb - 1] == '') return true;


    if (nb < 4) {
      return first3BytesRg.test(splitted[nb - 1]);
    }
    return fourthByteRg.test(splitted[nb - 1]);
  }

  private processError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return _throw({ code: 0, messages: ['Application crashed. Please try to refresh the page.'] });
    }
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,

    if (error.status === 400) {
      const messages = [];
      if (Array.isArray(error.error.errors)) {
        for (const i in error.error.errors) {
          messages.push(error.error.errors[i].join());
        }
      } else {
        messages.push(error.error.errors.global);
      }
      return _throw({ code: 400, messages });
    }

    if (error.status === 401) {
      return _throw({ code: 401, messages: ['Unauthorized. Please log in'] });
    }
    if (error.status === 403) {
      return _throw({ code: 403, messages: ['Action is not allowed'] });
    }
    if (error.status === 404) {
      return _throw({ code: 404, messages: ['Can\'t process the request. Requested URL not found.'] });
    }

    if (error.status === 422) {
      const errors = error.error.errors;
      const messages = errors ? Object.values(errors).map((item) => item) : [];
      return _throw({ code: 422, messages });
    }

    if (error.status === 500) {
      return _throw({ code: 500, messages: ['Internal server error'] });
    }

    if (error.status === 503) {
      return _throw({ code: 503, messages: ['Service Unavailable'] });
    }

  }


  private showNotificationError(iconType: string, color: string, notificationType: string, description: string, width: string): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };
    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data, nzStyle: { width: width ?? '385px' } });
  }

}
