import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { switchMap } from 'rxjs/internal/operators';

import { NzDrawerRef } from 'ng-zorro-antd/drawer';

import { IAlert } from '@core/interfaces';
import { ErrorsHandlingService } from '@core/services';
import { AlertsService } from '@modules/alerts/services';
@Component({
  selector: 'app-alerts-drawer',
  templateUrl: './alerts-drawer.component.html',
  styleUrls: ['./alerts-drawer.component.scss']
})
export class AlertsDrawerComponent implements OnInit, AfterContentInit {
  @Input() data: {
    alertsCount: number;
    alertsArray: IAlert[];
  };

  public drawerMaxHeight = 'auto';
  public drawerBodyMaxHeight = 'auto';
  public drawerContentMaxHeight = 'auto';

  public set alertsCount(value: number) {
    this._alertsCount = value;
  }

  public get alertsCount(): number {
    return this._alertsCount;
  }

  public set alertsArray(value: IAlert[]) {
    this._alertsArray = value;
  }

  public get alertsArray(): IAlert[] {
    return this._alertsArray;
  }

  private _alertsCount: number;
  private _alertsArray: IAlert[];

  constructor(
    private drawerRef: NzDrawerRef<AlertsDrawerComponent>,
    private router: Router,
    private alertsService: AlertsService,
    private errorsHandlingService: ErrorsHandlingService,
  ) { }

  ngAfterContentInit(): void {
    this.drawerMaxHeight = window.innerHeight - 10 + 'px';
    this.drawerBodyMaxHeight = window.innerHeight - 50 + 'px';
    this.drawerContentMaxHeight = window.innerHeight - 100 + 'px';
    window.addEventListener('resize', () => {
      this.drawerMaxHeight = window.innerHeight - 10 + 'px';
      this.drawerBodyMaxHeight = window.innerHeight - 50 + 'px';
      this.drawerContentMaxHeight = window.innerHeight - 100 + 'px';
    });
  }

  ngOnInit(): void {
    this.alertsCount = this.data.alertsCount;
    this.alertsArray = this.data.alertsArray;
  }

  public readAllAlerts(): void {
    this.alertsService.setReadStatusForAlert()
      .subscribe(res => {
        this.getAlerts();
      },
        error => this.errorsHandlingService.handleError(error));
  }

  public readAlert(alertId: number): void {
    this.alertsService.setReadStatusForAlert(alertId)
      .subscribe(res => {
        this.getAlerts();
      },
        error => this.errorsHandlingService.handleError(error));
  }

  public showAllAlerts(): void {
    this.setUserActionCount(1);
    this.router.navigate(['alerts']);
    this.drawerRef.close();
  }

  private getAlerts(): void {
    this.alertsService.getAlerts({ is_read: false })
      .pipe(
        switchMap(res => {
          this.alertsCount = res.totals;
          this.alertsService.alertsCount.next(res.totals);
          return this.alertsService.getAlerts({});
        })
      )
      .subscribe(res => {
        const alertsArray = res.data.length > 10 ? res.data.slice(0, 10) : res.data;
        this.alertsService.alertsData.next(alertsArray);
        this.alertsService.alertsDataObs.subscribe(res => this.alertsArray = res);
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private setUserActionCount(value: number): void {
    this.alertsService.setUserActionCount(value).subscribe(res => { },
      error => this.errorsHandlingService.handleError(error));
  }
}
