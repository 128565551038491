import { Component, OnInit, Input, AfterContentInit, Output, EventEmitter } from '@angular/core';

import { DropDownItem } from '@core/interfaces';

@Component({
  selector: 'app-column-builder',
  templateUrl: './column-builder.component.html',
  styleUrls: ['./column-builder.component.scss']
})
export class ColumnBuilderComponent implements OnInit, AfterContentInit {

  @Input() items: {
    attr: string,
    label: string,
    active: boolean,
    disabled: boolean,
    value: string
  }[];
  @Input() iconClass: string;
  @Input() multi: string;
  @Input() type: string;
  opened = false;
  @Output() optionChanged = new EventEmitter<{ attr: string, label: string, active: boolean, disabled: boolean, value: string }[]>();
  @Output() isOpened = new EventEmitter<boolean>();
  @Output() selectElement = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.items = this.items.filter(element => element.label !== '');
    this.items.forEach(element => {
      if (element.attr === 'date_export' || element.attr === 'date') {
        element.disabled = true;
      }
    });
  }

  ngAfterContentInit(): void {
    document.body.addEventListener('click', () => {
      if (this.opened) {
        this.opened = false;
      }
    });
  }

  onDropdownBodyClicked(e: MouseEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  toggleOpened(e: MouseEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.opened = !this.opened;
  }

  toggleSelection(e: MouseEvent, attr: string, active: boolean, value: string): boolean {
    e.preventDefault();
    e.stopImmediatePropagation();
    if (this.items.find(i => i.attr === attr).disabled) {
      return;
    }
    if (!this.multi) {
      this.onItemSelected(attr);
      this.opened = false;
      this.optionChanged.emit(this.items);
      return false;
    }
    if (this.type === 'drop') {
      if (this.items.filter(element => element.active === true).length > 2) {
        this.onMultiItemSelected(attr);
        this.optionChanged.emit(this.items);
        this.isOpened.emit(this.opened);
        const activeElement = false;
        this.selectElement.emit({ attr, activeElement });
      } else if (this.items.filter(element => element.active === true).length <= 2) {
        const activeElement = true;
        this.onMultiSelectedItem(attr);
        this.selectElement.emit({ attr, activeElement });
      }
    } else if (this.type === 'reports') {
      this.onMultiItemSelected(attr);
      if (this.items.filter(element => element.active === true).filter(element => element.value === 'metric').length === 1) {
        const array = this.items.filter(element => element.active === true).filter(element => element.value === 'metric');
        this.onMultiDisabled(array[0].attr);
      } else if (this.items.filter(element => element.active === true).filter(element => element.value === 'metric').length > 1) {
        this.items.forEach(element => {
          if (element.active === true && element.value === 'metric') {
            element.disabled = false;
          }
        });
      }
      if (this.items.filter(element => element.active === true).filter(element => element.value === 'attribute').length === 1) {
        const array = this.items.filter(element => element.active === true).filter(element => element.value === 'attribute');
        this.onMultiDisabled(array[0].attr);
      } else if (this.items.filter(element => element.active === true).filter(element => element.value === 'attribute').length > 1) {
        this.items.forEach(element => {
          if (element.active === true && element.value === 'attribute') {
            element.disabled = false;
          }
          if (element.attr === 'date_export') {
            element.disabled = true;
          }
        });
      }
    } else {
      this.onMultiItemSelected(attr);
      this.optionChanged.emit(this.items);
      this.isOpened.emit(this.opened);
      const activeElement = !active;
      this.selectElement.emit({ attr, activeElement });
    }
  }

  apply(): void {
    this.optionChanged.emit(this.items);
    this.isOpened.emit(this.opened);
  }

  private onMultiDisabled(attr: string): void {
    this.items.forEach((i: DropDownItem) => {
      if (i.attr === attr) {
        i.disabled = true;
      }
    });
  }

  private onItemSelected(attr: string): void {
    this.items.forEach((i: DropDownItem) => {
      if (i.attr !== attr) {
        i.active = false;
      } else {
        i.active = true;
      }
    });
  }


  private onMultiItemSelected(attr: string): void {
    this.items.forEach((i: DropDownItem) => {
      if (i.attr === attr && i.attr !== 'date') {
        i.active = !i.active;
      }
    });
  }

  private onMultiSelectedItem(attr: string): void {
    this.items.forEach((i: DropDownItem) => {
      if (i.attr === attr) {
        i.active = true;
      }
    });
  }

}
