import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, FillStateService } from '../../services';
import { UserRole } from '@core/enum';

@Injectable()
export class AppConfig {
  constructor(
    private authService: AuthService,
    private fillStateService: FillStateService,
    private router: Router,
  ) { }

  async init(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (this.authService.isAuthenticated() && this.authService.userRole !== UserRole.external) {
        this.fillStateService.handle();
        try {
          const res = await this.authService.check().toPromise();
        } catch (error) {
          this.router.navigate(['login']);
        }
      }

      setTimeout(() => resolve(), 100);
    });
  }
}